import React, { useState, useRef, useEffect } from 'react'
import { Box, Paper, Stack, Typography, Button, TextField } from '@mui/material'
import { Player } from '@lottiefiles/react-lottie-player'
// import { AppLogoIcon } from '../../../components/Icons'
import baseUrl from '../../../config/baseUrl'
// import SquareLogo from '../../../assets/images/Lawtech icon square.png'
import { useSnackbar } from '../../../components/SnackbarProvider'
interface Props {
  onSuccess: () => void
  backotp: any
  registrationDetail: any
}

const EmailVerification = ({ onSuccess, backotp, registrationDetail }: Props) => {
  const storedEmail = localStorage.getItem('storeEmail')

  const [otp, setOTP] = useState(['', '', '', ''])
  const inputRefs = useRef<HTMLInputElement[]>([])
const showSnackbar=useSnackbar()

  const [backendOtp, setBackendOtp] = useState<any>(backotp)

  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(true)

  const handleOTPChange = (text: any, index: number) => {
    const newOTP = [...otp]
    newOTP[index] = text.replace(/[^0-9]/g, '')
    setOTP(newOTP)

    if (text.length === 1 && index < 3) {
      inputRefs.current[index + 1]?.focus()
    }
  }

  // useEffect(() => {
  //   storedEmail && console.log(storedEmail)
  // }, [storedEmail])

  useEffect(() => {
    // Start a timer for 6 minutes (360,000 milliseconds)
    const timer = setTimeout(() => {
      setIsResendButtonDisabled(false) // Enable the button after 6 minutes
    }, 30000)

    // Cleanup the timer to prevent memory leaks
    return () => {
      clearTimeout(timer)
    }
  }, []) // The empty dependency array ensures this effect runs once when the component mounts

  const resendOtp = async () => {
    try {
      const resendOtpResponse = await fetch(`${baseUrl}/api/auths/resendotp`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Email: registrationDetail?.email,
        }),
      })

      const data = await resendOtpResponse.json()

      if (data.status === true) {
        // Handle successful response

        localStorage.setItem('userRegisteredId', data?.user?.id)
        localStorage.setItem('userId', data?.user?.id)
        localStorage.setItem('email', data?.user?.Email)

        sessionStorage.setItem('userRegisteredId', data?.user?.id)
        sessionStorage.setItem('userId', data?.user?.id)
        sessionStorage.setItem('email', data?.user?.Email)
        setBackendOtp(data?.user?.Otp)

        showSnackbar('New OTP sent to your email-id','success')
      }
    } catch (error: any) {
      // console.log('Error in resend otp :', error.message)
      showSnackbar(error?.message,'error')
    }
  }

  const checkOtp = async () => {
    try {
      const otpResponse = await fetch(`${baseUrl}/api/auths/emailverification1`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Otp: backendOtp,
          Email: storedEmail,
        }),
      })

      const otpResponseData = await otpResponse.json()

      if (otpResponseData.status === true) {
        onSuccess()
      } else {
        // console.log('Error in Email Verification')
        showSnackbar('Error in Email Verification','error')
      }
    } catch (error: any) {
      // console.log('Error in OTP verification : ', error.message)
      showSnackbar('Error in OTP verification', 'error')
    }
  }

  const handleClick = () => {
    const otpCheck = otp.join('')

    if (otpCheck === backendOtp) {
      checkOtp()
    } else {
      showSnackbar('Please enter a valid OTP','warning')
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        overflow: 'hidden',
        bgcolor: 'common.white',
      }}
    >
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: 1,
          maxWidth: 524,
          minHeight: 562,
          borderRadius: 1,
          p: 1.5,
        }}
      >
        <Stack spacing={2}>
          <Stack alignItems='center' spacing={2.5}>
            {/* <AppLogoIcon sx={{ width: 91, height: 62 }} /> */}
            {/* <img src={SquareLogo} alt='' height='60px' /> */}
          </Stack>
          <Player
            autoplay
            loop
            src='https://lottie.host/998a3e96-c186-4c72-8a5b-7939c3a7a45f/GjPnUluhZ8.json'
            style={{ height: 120, width: 120 }}
          />
          <Stack alignItems='center'>
            <Stack mb={3.3} maxWidth={312}>
              <Typography variant='h5' align='center'>
                Verify your Email Id
              </Typography>
              <Typography variant='body1' align='center'>
                To start with us, we need to verify your Email Id{' '}
                <Typography component='span' variant='body2' color='textSecondary'>
                  {storedEmail}
                </Typography>
              </Typography>
            </Stack>
            <div className='otp-container' style={{ paddingTop: '0px' }}>
              {otp.map((digit, index) => (
                <TextField
                  key={index}
                  type='text'
                  variant='outlined'
                  placeholder='0'
                  value={digit}
                  onChange={(e) => {
                    handleOTPChange(e.target.value, index)
                  }}
                  inputRef={(ref) => (inputRefs.current[index] = ref as HTMLInputElement)}
                  style={styles.otpInput}
                  inputProps={{
                    maxLength: 1,
                  }}
                />
              ))}
            </div>
            <Typography variant='body2' component='p'>
              OTP is valid for 5 min only
            </Typography>
            <Button
              variant='contained'
              sx={{ maxWidth: 160, marginTop: '30px' }}
              onClick={handleClick}
            >
              Verify
            </Button>
            <Button
              variant='outlined'
              sx={{ maxWidth: 160, marginTop: '10px' }}
              onClick={resendOtp}
              disabled={isResendButtonDisabled}
            >
              Resend
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  )
}

export default EmailVerification

const styles: Record<string, React.CSSProperties> = {
  otpInput: {
    width: '60px',
    height: '60px',
    fontSize: '24px',
    textAlign: 'center',
    margin: '8px',
  },
}
