import React from 'react'
import { Drawer, Stack } from '@mui/material'
import Tabs from './Tabs'
import History from './History'
import { useSidebarContext } from '../../../contexts/Old_SidebarContext'
import Actions from './Actions'
import Filters from './Filters'

const sidebarWidth = 335

const LeftSidebar = () => {
  const { sidebarOpen } = useSidebarContext()
  const { selectedTab, setSelectedTab } = useSidebarContext()
  const token = localStorage.getItem('token')
  const handleTabClick = (selected: number) => {
    setSelectedTab(selected)
  }

  return (
    <Drawer
      variant='persistent'
      anchor='left'
      open={sidebarOpen}
      elevation={0}
      sx={{
        width: sidebarWidth,
        flexShrink: 0,
        // zIndex: { xs: 'auto', sm: 1050 },
        position: 'relative',
        '& .MuiDrawer-paper': {
          width: sidebarWidth,
          boxSizing: 'border-box',
          position: 'fixed',
          top: { xs: '75px', md: '62px' },
          border: 'none',
          boxShadow: 'none',
        },
      }}
    >
      {token && token !== null && <Stack
        spacing={2.5}
        width={335}
        flexGrow={1}
        height='auto'
        bgcolor='common.white'
        py={0.5}
        pr={3}
      >
        <Actions />
        <Tabs selected={selectedTab} onClick={handleTabClick} />
        {selectedTab === 1 && <History />}
        {selectedTab === 2 && <Filters />}
      </Stack>}

    </Drawer>
  )
}

export default LeftSidebar
