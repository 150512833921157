import React, { useState } from 'react'
import { Link as RouteLink, } from 'react-router-dom'
import { Box, Button, Typography, Link } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { type InferType } from 'yup'
import TextField from '../../../components/shared/TextField'
import { forgotPasswordSchema } from '../../../lib/validation'
import baseUrl from '../../../config/baseUrl'
import { useSnackbar } from '../../../components/SnackbarProvider'

type FormData = InferType<typeof forgotPasswordSchema>

const ForgotPasswordForm = () => {


  const {
    control,
    handleSubmit,

    formState: { errors },
    reset,
  } = useForm<FormData>({
    resolver: yupResolver(forgotPasswordSchema),
    defaultValues: {
      email: '',
    },
  })

  const [isButtonDisable, setIsbuttonDisable] = useState(true)
const showSnackbar = useSnackbar()
  const forgetPassword = async (data: any) => {
    try {
      const forgetPasswordResponse = await fetch(`${baseUrl}/api/auths/forgotpass`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Email: data.email,
        }),
      })

      const forgetPasswordResponseData = await forgetPasswordResponse.json()
      if (!forgetPasswordResponse?.ok) {
        showSnackbar(forgetPasswordResponseData?.message,'error')
      }

      if (forgetPasswordResponse?.ok) {
        // const email = data.email
        showSnackbar(forgetPasswordResponseData?.message,'success')

        sessionStorage.setItem('email', forgetPasswordResponseData?.data?.Email)
        sessionStorage.setItem('resetToken', forgetPasswordResponseData?.data?.ResetToken)
        localStorage.setItem('email', forgetPasswordResponseData?.data?.Email)
        localStorage.setItem('resetToken', forgetPasswordResponseData?.data?.ResetToken)
        reset()
      }
    } catch (error: any) {
      // console.log('Error in Forget Password : ', error.message)
      showSnackbar('Error in Forget Password','error')
    }
  }

  const handleEmailChange = (emailValue: string) => {
    setIsbuttonDisable(emailValue === '')
  }

  const onSubmit = (data: any) => {
    forgetPassword(data)

  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: 2,
          }}
        >
          <Box sx={{ minHeight: 102 }}>
            <Controller
              name='email'
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <TextField
                  type='email'
                  name={name}
                  onChange={(e) => {
                    onChange(e)
                    handleEmailChange(e.target.value) // Call the email change handler
                  }}
                  value={value}
                  error={errors.email?.message}
                  label='Email'
                />
              )}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '100%',
              gap: 2.5,
            }}
          >
            <Button type='submit' variant='contained' disabled={isButtonDisable}>
              Submit
            </Button>
            <Typography variant='subtitle1'>
              Back to
              <Link
                component={RouteLink}
                variant='subtitle2'
                to='/auth/login'
                underline='hover'
                color='textSecondary'
                ml={0.5}
              >
                Login
              </Link>
            </Typography>
          </Box>
        </Box>
      </form>
      {/* <EmailVerificationDialog open={openDialog} email={email} onClose={handleClose} /> */}
    </>
  )
}

export default ForgotPasswordForm
