export const CivilSubCategories = [
  {
    label: 'Corruption',
    value: 'corruption',
  },
  {
    label: 'Divorce',
    value: 'divorce',
  },
  {
    label: 'Harassment',
    value: 'harassment',
  },
  {
    label: 'Inheritance',
    value: 'Inheritance',
  },
  {
    label: 'Construction',
    value: 'Construction',
  },
  {
    label: 'Medical',
    value: 'Medical',
  },
]

export const acts = [
  'Section 5 of The Administrative Tribunals Act, 1985',
  'Section 13 of The Actuaries Act, 2006',
  'Section 2 of The Acquisition of Certain Area At Ayodhya Act, 1993',
  'Section 14 of The Academy Of Scientific And Innovative Research Act, 2011',
  'Section 20 of the Broach and Kaira Incumbered Estates Act, 1877',
  'Section 12 of the Building and other Construction Workers (Regulation of Employment and Conditions of Service)Act, 1996',
  'Section 3 of the Calcutta High Court (Extension of Jurisdiction) Act, 1953',
  'Section 9 of The Company Secretaries Act, 1980',
  'Section 26 of The Commission for Air Quality Management in National Capital Region and Adjoining Areas Act, 2021',
  'Section 15 of the Coinage Act, 2011',
  'Section 26 of THE COASTAL AQUACUL TURE AUTHORITY ACT, 2005',
  'Section 4 of The Press Council Act, 1978',
  'Section 3 of Prevention of Terrorism Repeal Act, 2004',

]

export const judgements = [
  'What are the facts in the case Magic Eye Developers Pvt. Ltd versus M/s. Green Edge Infrastructure Pvt. Ltd. & Ors. Etc. (Civil Appeal Nos. 3634-37 of 2023)',
  'What are the issues in the case Sirajudheen versus Zeenath & Others(Civil Appeal No. 1491 of 2023 ?',
  'What are the list of Citations and other references in case of Central Bureau of Investigation Versus Kapil Wadhawan & Another.Criminal Appeal No. 391 of 2024?',
  'What is the Summary or background of the case of Dinesh Gupta Versus The State of Uttar Pradesh & Anr Criminal Appeal No(s). 214 of 2024?',
  'What is the Judgment or Decision or Findings of Trial Court in case of Rajendhiran versus Muthaiammal @ Muthayee & Ors, Civil Appeal No.37 of 2024?',
  'What is the Judgment or Decision or Findings of the Supreme Court of India in case of Satish P. Bhatt Versus The State of Maharashtra & Anr.Criminal Appeal No.42 of 2024?',
  'What are the legal Provisions or Sections included in the case of State of NCT of Delhi versus Raj Kumar @ Lovepreet @Lovely Criminal Appeal No.43 of 2024?',
  'What is the Judgment or Decision or Findings of the Supreme Court of India in case of Reliance Life Insurance Company Ltd. & Anr. Versus Jaya Wadhwani Civil Appeal No. 35 of 2024?',
  'What are the issue in the of case of Raja Gounder and Others Versus M. Sengodan and Others Civil Appeal No. 600 of 2024?',
  'What are the list of Citations and other references in case of Mariam Fasihuddin & Another. v. State by Adugodi Police Station & Another.Criminal Appeal No. 335 of 2024?',
  'What is the Judgment or Decision or Findings of the High Court in case of Gurdev Singh Bhalla Versus State of Punjab and Others Criminal Appeal No. 120 of 2024?',
  'What is the Summary or background of the case of Delhi Development Authority Versus Hello Home Education Society Civil Appeal Nos. 3659-3660 of 2023?',
  'What is the Summary or background of the case DBS Bank Limited Singapore versus Ruchi Soya Industries Limited and Another Civil Appeal No. 9133 of 2019?',
]

export const formats = [
  'Format of the Notice Landlord to Tenant for Eviction',
  'Format of the Notice for Breach of Leave and Licence Agreement',
  'Format of the Notice for Compensation for Defect in Goods',
  'Format of the Notice for Dishonour of Cheque',
  'Format of the Notice for Expulsion of a Partner or Notice under section 33 of Indian Partnership Act, 1932',
  'Format of the Notice for Insurance Claim',
  'Format of the Notice to Terminate Contract',
  'Format of the Notice for Trespass and Damage to Property',
  'Format of the Notice to Refrain from Acting as Director of the Company',
  'Format of the Notice to Claim Maintenance',
  'Format of reply notice to notice for eviction',
  'Format of the Notice for Recovery of Money and Appointment of Arbitrator',
  'Format Application for grant of probate.',
  'Format for Petition for Dissolution of Marriage',
  'Format of Suit for Medical Negligence',
]

export const affidavits = [
  'Affidavit for Lost of Deed of Conveyance / Deed of Sale',
  'Format for affidavit in support of Plaint in court',
  'Draft for an Affidavit in support of Petition filed',
  'Format for grant of Probate for Lost of Will',
  'Draft for Adoption deed under Hindu Adoptiion Act(to natural gurdian / parent)',
  'Draft for Adoption deed to Orphanage under Hindu Adoptiion Act',
  'Draft for Adoption deed under Hindu Adoptiion Act(to Juvenille justice committee)',
  'Draft for adoption deed by unmarried women to widow women under Hindu adoption act',
  'Draft for adoption deed by unmarried women under Hindu adoption act',
  'Due to some medical reasons, my client was not able to file the petition, please provide me delay condonation draft',
  'Affidavit Format for injunction',
  'My client filed a case against a property owner regarding the road passed from my clients property, please provide me a injunction application',
  'Format for grant of succession certificate',
]

export const deedApplications = [
  'Format for Deed of Indemnity',
  'The grand father wishes to gift a car to my client, provide me the Format for gift deed',
  'Draft for Indemnity Bond',
  'Format Of Lease Deed for open plot of land',
  'Format Lease of Flat for Residential Purpose',
  'Format Of AGREEMENT FOR SALE',
  'Format Of DEED OF DISSOLUTION OF PARTNERSHIP',
  'Format Of DEED OF MORTGAGE ',
  'Format Of DEED OF PARTITION BETWEEN TWO CO - OWNERS',
  'Format Of Deed of Mortgage under Transfer of Property Act, 1882',
  'Draft / Format Of GENERAL POWER OF ATTORNEY',
  'Format of Leave and Licence Agreement for Commercial Purpose',
  'Format of Leave and Licence Agreement.',
  'Format Of PARTITION DEED.',
  'Format Of PARTNERSHIP DEED',
  'Format Of RELEASE DEED(Immovable Property)',
  'Format Of RELEASE DEED(Movable Property)',
  'Format Of SPECIAL POWER OF ATTORNEY',
  'Format of Suit for Cancellation of sale deed and Power of Attorney',
  'Format Of TRUST DEED',
  'Format Of Will under Indian Succession Act, 1925',
  'Format for Gift Deed(IMMOVABLE PROPERTY) under section 122 of the Transfer Of Property Act 1882',
  'Format Of AGREEMENT FOR SALE',
  'Draft for Dissolution of Partnership',
  'Draft for Suit of Dissolution of Partnership',
]

export const arbitration = [
  'Format for Arbitration Agreement under section 7(1) of the Arbitration and Conciliation Act, 1996.(For Individuals)',
  'Format for Arbitration Agreement under section 7(1) of the Arbitration and Conciliation Act, 1996.(For Company)',
  'Format for Arbitration Agreement under section 7(1) of the Arbitration and Conciliation Act, 1996.(For Partnership Firm)',
]

export const districtAndSessionsCourtApplications = [
  'Format for Application for recalling witness under section 217 ofCrPC',
  'Format for Criminal Appeal against the judgment',
  'Format for Application for service of summons by substituted service',
  'Format for Application under section 70(2) CrPC for cancellation of warrant of arrest',
  'Format for Caveat application under section 148 - A of the Civil Procedure Code',
  'Format for APPLICATION FOR CONDONATION OF DELAY',
  'Format First bail Application under section 439 of CrPC',
  'Format for Appeal',
  'Format for Appliaction for Stay',
  'Format for Criminal Appeal against the judgment',
  'Format for First Anticipatory bail Application under section 438 of CrPC',
  'Draft a complaint under Consumer Protection Act',
  'Format for In the Matter of section 439 of CrPC',
]

export const drtApplications = [
  'Application under section 151 of CPC to be filed before the hon\'ble debt recovery tribunal for waving of the cost imposed on defendant vide order passed by this hon\'ble tribunal',
  'Application to be filed before hon\'ble debt recovery tribunal seeking dismissal of the application under section 19(4) of the recovery of debts due to banks and financial institutions act, 1993',
]

export const familyCourtApplications = [
  'Format for Petition for divorce by mutual consent',
  'Format for Application for permanent alimony and maintenance',
  'Format for Nullity of Marriage, under section 12 of Hindu Marriage Act 1955',
  'Format for Petition for Dissolution of Marriage on the ground of Desertion',
  'Format for Petition for divorce by on the ground of cruelty(Specimen 1)',
  'Format for Petition for divorce by on the ground of cruelty(Specimen 2)',
  'Format for Petition for judicial separation(Specimen 1 and 2)',
  'Format for Petition for Maintenance under section 125 CrPC',
  'Format for Reply for Petition for restitutional of conjugal rights(Specimen 1)',
  'Format for Reply for Petition for restitutional of conjugal rights(Specimen 2)',
  'Format for Reply for Petition for restitutional of conjugal rights(Specimen 3)',
  'Format for Reply to Injunction Application',
  'Format for Reply to the Petition for Divorce on the Ground of Cruelty',
  'Format for Petition for restitutional of conjugal rights(Specimen 1)',
  'Format for Petition on the Ground of Desertion and Adultery(Specimen 1)',
  'Format for Petition on the Ground of Desertion and Adultery(Specimen 2)',
  'Format for Petition under section 128 of the Criminal Procedure Code',
  'Format for Reply to Petition for Dissolution of Marriage on the ground of Dessertion',
  'Format for Petition under section 24 of the Hindu Marriage Act, 1955 for maintenance pendente lit',
]

export const highCourtApplications = [
  'Format for APPLICATION FOR CONDONATION OF DELAY',
  'Format for (Revision)  (Specimen 1)',
  'Format for (Revision) Specimen 2.',
  'Format for Appeal(Specimen 2)',
  'Format for Petition U / S 115 of Code of Civil Procedure',
]

export const writPetitions = [
  'Format for WRIT OF CERTIORARI',
  'Format for WRIT OF QUO WARRANTO',
  'Format WRIT OF HABEAS COPRUS (Specimen 1 prompt is required to be mentioned)',
  'Format for WRIT OF MANDAMUS ',
  'Format for WRIT OF PROHIBITION AND OTHER DIRECTION OR ORDER  Under Article 226 of Constitution of India. (Specimen 1)',
]

export const jmfcApplications = [
  'Format for Application for appointment of court commissioner.',
  'Format for Application for enhancement of maintenance',
  'Format for Application for enhancement of maintenance. (Specimen 1)',
  'Format for Application for taking Adjournment',
  'Format for Application Petition by Accused under section 205 of CrPC',
  'Format for Application under section 70(2) CrPC for cancellation of warrant of arrest',
  'Format for Application under section 140(No Fault Liability) of the Motor Vehicles Act, 1988 for claim',
  'Format for Caveat application under section 148 - A of the Civil Procedure Code.',
  'Draft for Complaint under section 498A',
  'Format of Defamation Case Under Section 499(Specimen 2)',
  'Draft for Suit For Compensation for Negligence',
  'Draft for Suit for damages for malicious prosecution',
  'Format Application for amendment of pleadings(Specimen 1)',
  'Format Application for grant of probate',
  'Format Application under Order XX rule 12 of Civil Procedure Code',
  'Format for First bail Application under section 436 of CrPC',
  'Format for Appeals under Consumer Protection Act(Specimen 1)',
  'Format for Application for appointment of Court Receiver(Specimen 1)',
  'Format for Application for arrest before judgment',
  'Format for Application for Attachment before Judgment',
  'Format for Application for restoration of suit under O IX rule 4 read with section 151 CPC(Specimen 1)',
  'Format for Application for temporary injunction. (Specimen 1)',
  'Format for Application for temporary injunction. (Specimen 2)',
  'Format for Application on behalf of the legal heirs of the plaintiff',
  'Format for Application to Application for restoration of appeal under Order IX rule 7 read with section 151 of Civil Procedure Code',
  'Format for Application to sue as an indigent person',
  'Format for Application under section 140(No Fault Liability) of the Motor Vehicles Act, 1988 for claim',
  'Format for Application under section 451 of CRPC',
  'Format for Application under sections 166 and 140 of the Motor Vehicles Act, 1988 for grant of compensation',
  'Format for Complaint for the offence of cheating under section 420 of IPC',
  'Format for Complaint for the offence of Defamation',
  'Format for Complaint under section 138 of the Negotiable Instruments Act',
  'Format for Consumer Complaint for Defect in Goods',
  'Format for Consumer Complaint for Deficiency in Services',
  'Format for Criminal Complaint under section 420 of Indian Penal Code and under section 8, 13 of Maharashtra Ownership of Flats Act, 1963',
  'Format for Recovery of Insurance Claim and for Compensation',
  'Format for Reply to Interim Application',
  'Format for Review Application U / S 114 Read with O XLVII of CPC.',
  'Format for Suit for Specific Performance of Contract(Specimen 1)',
  'Format for Suit for Specific Performance of Contract(Specimen 2)',
  'Format for Written statement in the suit for recovery of price for goods sold and delivered',
  'Format Of Codicil under Indian Succession Act, 1925',
  'Format of Suit against trespasser for recovery of possession and compensation',
  'Format of Suit based on Promissory note',
  'Format of Suit for Compensation for Negligence ',
  'Format of Suit for Declaration and injunction and for cancellation of Instruments',
  'Format of Suit for Declaration and Permanent Injunction(Public Nuisance).',
  'Format of Suit for Malicious Prosecution(Specimen 1 promot is required)',
  'Format of Suit for Medical Negligence',
  'Format of Suit for Nuisance Under Section 268 of Indian Penal Code 1860. ',
  'Format of Suit for Partition Specimen No. 1 ',
  'Format of Suit for Tresspass Under Section 441 of Indian Penal Code 1860 Sepcimen No. 2  ',
  'Format of Suit for Tresspass Under Section 441 of Indian Penal Code 1860 Sepcimen No. 1',
  'Format of Suit Suit for compensation for trespass',
  'ormat Petition under section 389(3) CrPC for grant of bail on conviction',
  'Format Suit for recovery of money for the Goods / equipment sold',
  'Format Under Complaint for Criminal Trespass Under Section 441 of IPC',
  'Format Under Section 494 of IPC(Marrying again during lifetime of husband or wife)',
  'Format of Suit for Evicition Under Section 16 of Maharashtra Rent Control Act 1999(Specimen 1 prompt is required to be mentioned)',
  'Format of Suit for Nuisance Under Section 268 of Indian Penal Code 1860(Specimen No. 2  prompt is required to be mentioned)',
  'Format of Suit for Partition( in JMFC prompt is required to be mentioned)',
  'Format for Writtern Statements on behalf of the defendant Suit Relating to Nuisance.',
]

export const notices = [
  'Format of Notice for Notice by Landlord to Tenant for Eviction',
  'Format of Notice for Breach of Leave and Licence Agreement(NOTICE By R.P.A.D.prompt is required to be mentioned)',
  'Format of Notice for Cancellation of Tender Process and Call for Fresh Quotes',
  'Format of Notice for Notice for Compensation for Defamation',
  'Format of Notice for Notice for Compensation for Defect in Goods',
  'Format of Notice for Notice for Compensation for Deficiency in Services',
  'Format of Notice for Notice for compensation for permanent disablement and business loss',
  'Format of Notice for Notice for Dishonour of Cheque(NOTICE By R.P.A.D.prompt is required to be mentioned)',
  'Format of Notice for Dissolve the Marriage by Mutual Consent',
  'Format of Notice for Expulsion of a Partner',
  'Format of Notice for Notice for Insurance Claim',
  'Format of Notice for Notice to Terminate Contract',
  'Format of Notice for Recovery of Money and Appointment of Arbitrator',
  'Format of Notice for Recovery of Price of Furniture Sold',
  'Format of Notice for Notice for Restitution of Conjugal Rights',
  'Format of Notice for Specific Performance of Contract',
  'Format of Notice for Trespass and Damage to Property',
  'Format of Notice Reply Pertaining to Divorce Case',
  'Format of reply notice to notice for eviction',
  'Format of Notice for Notice to Claim Maintenance',
  'Format of Notice to Execute Cancellation of Sale Deed and Power of Attorney and for Compensation',
  'Format of Notice to Refrain from Acting as Director of the Company',
  'Format of Notice for Notice to Refrain from Creating Third Party Interest in Property',
  'Format of Notice under section 80 of CPC',
  'Format of Notice for Notice under Section 138 of Negotiable Instruments Act',
]

export const biologicalDiversity = [
  'Give section 7 of the Biological Diversity Act, 2002',
  'Give section 7 of the Biological Diversity Act, 2002',
  'Give section 6 under the Compensatory Afforestation Fund Act, 2016',
  'Section 9 of The Indian Forest Act, 1927',
  'Explain all the provisions of section 2 under the Wild Life (Protection) Act, 1991',
  'How the Compensatory Afforestation Fund  is used under Compensatory Afforestation Fund Act, 2016',

]

export const climateChangeAndPollution = [
  'Section 5 of The Air (Prevention and Control of Pollution)Act, 1981',
  'Give all the provisions of section 8 of the Water Prevention And Control of Pollution Act, 1974',
  'Give section 2 of the Environment (Protection) Act, 1986',

]

export const importantArticles = [
  'Give article 21 of Indian constitution',
  'Which article of Indian constitution deals with freedom of religion. Give the details related to this article.',
  'Which article of Indian constitution deals with freedom of speech.',
  'What are the limitations of article 19 under the Indian constitution',
  'List some constitutional bodies according to Indian constitution',
  'List some non constitutional bodies according to Indian constitution',
  'Is NITI Ayog a constitutional body',
  'Is Central Bureau of Investigation a constitutional body',
  'List the writs under article 32 of Indian constitution.',
  'How the president of India is elected.',
  'Give the preamble of Indian constitution.',
  'What comes under ordinary jurisdiction of supreme court of India.',
  'Which article of Indian constitution deals with right to education.',

]

export const economicOffences = [
  'Give all provisions of section 9 under the fugitive economic offenders act 2018.',
  'What is Section 3 of The Prevention of Money Laundering Act, 2002',
  'Which section of  The Prevention of Money Laundering Act, 2002 deals with the Procedure and manner of furnishing information',
  'Give name of bodies or committees formed under Prevention of Corruption Act, 1988 ',
  'What is the composition of Central Vigilance Commission',

]

export const civilProcedure = [
  'Section 2 of The Code of Civil Procedure, 1908',
  'Which section deals with the Costs for causing delay under the Code of Civil Procedure.',
  'What are the Powers of the Court in executing transferred decree under  The Code of Civil Procedure.',
  'Which section deals with  Arrest and detention under The Code of Civil Procedure, 1908.',
  'What are the powers of magistrate under civil procedure code.',

]

export const familyLaw = [
  'Give section 3 of Indian Succession Act.',
  'Give section 4 of Protection of Women from Domestic Violence Act',
  'section 6 of Births, Deaths and Marriages Registration Act, 1886',
  'Give  section 10 under The Hindu Adoptions and Maintenance Act, 1956',

]

export const divorce = [
  'Explain Section 4 of The Divorce Act 1869',
  'Give section 2 of Muslim Women Protection of Rights on Divorce Act, 1986',
  'Give Section 10 of The Divorce Act 1869',
  'What are the procedures of divorce under the Divorce act 1869.',
  'What is the section of mutual divorce under Hindu Marraige Act',
  'What does Restitution of Conjugal Rights expalins and under which section it is briefly explained ',
]

export const marriage = [
  'Give Section  2 of The Hindu Marriage Act, 1955 ',
  'Which section deals with Marriage Officers under The Special Marriage Act.',
  'Give all provisions of Section 8 of The Special Marriage Act.',
  'Which section deals with Solemnization of marriage under The Foreign Marriage act',
  'Give section 16 of Christian Marriage Act, 1872.',
  'Section 2 of The Prohibition of Child Marriage Act, 2006',
  'Give section 27 of Special Marriage Act, 1872.',
]

export const adoption = [
  'What is Section 2 of The Hindu Adoptions and Maintenance Act.',
  'Give Section 2 of the Juvenile Justice Care and Protection of Children act.',
  'What is Section 12 of The Hindu Adoptions and Maintenance Act.',
  'Give the provision of maintenance under the Hindu Adoptions and Maintenance Act.',

]

export const companiesBankruptcyAndInsolvency = [
  'Section 2 The Recovery of Debts and Bankruptcy Act, 1993',
  'Give all provisions of Section 3 of The Insolvency and Bankruptcy Code, 2016',
  'Give Section 4 of The Sick Industrial Companies act',
  'Is there any body or committee to be established under Insolvency and Bankruptcy act',
  'What are the powers of National Company Law Tribunal (NCLT) under Companies Act, 2013.',
  'How a company should be registered under Companies Act, 2013.',

]

export const contract = [
  'Give all provisions of Section 2 of The Indian Contract Act.',
  'Which section deals with Undue influence under The Indian Contract Act',
  'Give section 9 of Contract Labour (Regulation and Abolition) Act, 1970',
  'Give section 2 of The Securities Contracts Act, 1956',
]

export const bankingAndFinance = [
  'Section 2 of Reserve Bank of India Act, 1934',
  'Give all provisions of section 11 under Payment and Settlement Systems Act, 2007',
  'Explain section 10 of Banking Regulation Act, 1949',
  'Section 4 of The Securities and Exchange Board of India Act, 1992',
  'Give the powers of RBI under the Banking Regulation Act, 1949',
]

export const companiesLaw = [
  'Explain all provisions of section 12 under Companies Act, 2013',
  'Give all the provisions of section 9 under the Insolvency and Bankruptcy Code, 2016',
  'Give all the provisions of section 9 under the Insolvency and Bankruptcy Code, 2016',
  'Which section of the Competition Act, 2002 deals with Abuse of dominant position?',
  'Which section of the Competition Act, 2002 deals with  establishment of Commission.',
]

export const industrialRelation = [
  'Give section 5 of Trade Unions Act, 1926',
  'Give the important sections of Industrial Disputes Act, 1947',
  'What is the definition of factory according to the Factories Act, 1948',
  'Is there any body or committee established under Industrial Disputes act 1947',
  'Is there any body or committee established under Industrial Disputes act 1947',
]

export const labourAndEmploymentLaws = [
  'What should be minimum wage under Minimum Wages Act, 1948',
  'Give important sections of Sexual Harassment of Women at Workplace 2013',
  'Give all the provisions of Section 13A of Payment of Wages Act, 1936',
  'Pension Fund Regulatory and Development Authority Act, 2013',
  'Which establishments come under Occupational Safety, Health and Working Conditions ',
]

export const roadSafety = [
  'Explain Section 185 of the Motor Vehicle Act.',
  'What are the penalty for drunken driving',
  'Which section deals with Restrictions on the granting of learners licences under Motor Vehicles Act.',
  'Which section deals with Renewal of driving licences under Motor Vehicle Act.',
  'What is the penalty for drving without helmet',
  'What is the penalty for driving without license',
  'What is the penalty for jumping the signal',
]

export const sexualOffensesAndHarrashmentAct = [
  'Give Section 6 of Sexual Harassment of Women at Workplace Act, 2013',
  'Give all provisions of section 5 of Sexual Harassment of Women at Workplace Act, 2013',
  'What was Criminal Law (Amendment) Act, 2013',
  'Is there any body or committee under Sexual Harassment of Women at Workplace Act, 2013',
  'Which section of Indian penal code deals with eve teasing ',
  'Which section talks about the outrage modesty of a women and under which act does this section is charged',

]

export const murder = [
  'Which section of Indian Penal Code deals with  murder.',
  'Which section is related to drunken driving under Motor vehicle Act',
  'Which section deals with Culpable homicide under the Indian Penal Code.',
  'What are  the punishments for murder according to Indian Penal Code',

  'In how many days, a chargesheet is supposed to be filed by Police machinery in the offence of murder under Indian Penal Code',

]

export const fraudAndTheft = [
  'Give section 210 of Indian Penal Code',
  'Give provisions of section 379 under Indian Penal Code.',
  'Which section deals with using a forged document as genuine under the Indian Penal Code?',

  'Give all the provisions of Section 471 of the Indian Penal Code ',

]

export const criminalProcedure = [
  'Section 2 of The Code of Criminal Procedure 1973',
  'Section 4 of The Code of Criminal Procedure 1973',
  'Which section deals with Classes of Criminal Courts under The Code of Criminal Procedure 1973',
  'What are the powers of magistrate under the criminal procedure code.',
  'What are the powers of Sessions Judge under the criminal procedure code.',
  'What is the section of regular bail application under code of criminal procedure',
  'What is the section of Anticipatory bail application under code of criminal procedure',
  'What does section 167 (2) of code of criminal procedure expalins',
  'What does section 205 of code of criminal procedure expalins',
  'What does section 317 of code of criminal procedure expalins',

]

export const incomeTax = [
  'Give section 80D of Income Tax Act 1961.',
  'Which section deals with Deduction in respect of donations to certain funds, charitable institutions under Income Tax Act 1961',
  'Explain section 4 of Income Tax Act, 1961.',
  'What are the punishments of not paying income tax  under Income Tax Act 1961.',
  'Give important provisions of Black Money Undisclosed Foreign Income and Assets and Imposition of Tax Act',

]

export const indirectTax = [
  'Give Section 1 of The Goods and Services Tax (Compensation to States) Act, 2017',
  'Section 3 of The Goods and Services Tax (Compensation to States) Act, 2017',
  'Section 4 of Integrated Goods and Services Tax Act, 2017',
  'Explain all provisions of section 9 under the Central Goods and Services Tax Act, 2017',
  'Is there any body established under the Central Goods and Services Tax Act, 2017',
  'Who are the members of Goods and Services tax council',

]

export const caseBased = [
  'I was in possession of marijuana when police office caught me. now that officer is blackmailing me and is demanding 5000 rupees to let go the matter. How can i protect myself',
  'A vendor given me a check. When I submited in bank it got bounced. What remedies do I have.',
  'A police officer is harrashing me. What remedies do I have.',
  'An advocate is charging more fees to win the case. what can I do.',

]

export const domesticLegislationForInternationalLawAndConvention = [
  'Which act was passed to give affect to United Nations Convention on the Law of the Sea.',
  'Give Article 93 of The Geneva Conventions Act, 1960',
  'Give article 253 of Indian constitution.',
  'Which article of Indian constitution give power for implementing any treaty, agreement, or convention',
  'Which international body replaced the General Agreement on Tariffs and Trade (GATT)',
  'Which agreement is related with the establishment of WTO',

]

export const patentTrademarkAndGI = [
  'List important provisions under the Patents Act, 1970',
  'Give important provisions of section 5 under the Designs Act, 2000',
  'Give all the provisions of section 9 under the Bureau of Indian Standards Act, 2016',
  'Which section deals with the Prohibition of manufacture and sale of certain drugs and cosmetics under Drugs and Cosmetics Act, 1940',
  'List some items declared as geographical indicators under the Geographical Indications of Goods act 2002',
  'Give section 10 of Designs Act, 2000',
]

// ==========================================================================
export const CivilJudgesCategories = [
  {
    label: 'Dhananjaya Y. Chandrachud',
    value: 'dhananjaya y. chandrachud',
  },
  {
    label: 'Sanjay Kishan Kaul',
    value: 'sanjay kishan kaul',
  },
  {
    label: 'Sanjiv Khanna',
    value: 'sanjiv khanna',
  },
  {
    label: 'Bhushan Ramkrishna Gavai',
    value: 'bhushan ramkrishna gavai',
  },
]

export const CivilCourtsCategories = [
  {
    label: 'Supreme Court',
    value: 'supreme court',
  },
  {
    label: 'High Courts',
    value: 'high courts',
  },
  {
    label: 'District Courts',
    value: 'district courts',
  },
  {
    label: 'Lok Adalats/Village Courts',
    value: 'lok adalats',
  },
  {
    label: 'Tribunals',
    value: 'tribunals',
  },
]

export const CriminalSubCategories = [
  {
    label: 'Murder',
    value: 'murder',
  },
  {
    label: 'Dacoity',
    value: 'dacoity',
  },
  {
    label: 'Fraud',
    value: 'fraud',
  },
]

// export const Judjements = [
//   {
//     label:
//       'What are the facts in the case Magic Eye Developers Pvt. Ltd versus M/s. Green Edge Infrastructure Pvt. Ltd. & Ors. Etc. (Civil Appeal Nos. 3634-37 of 2023)',
//     value:
//       'What are the facts in the case Magic Eye Developers Pvt. Ltd versus M/s. Green Edge Infrastructure Pvt. Ltd. & Ors. Etc. (Civil Appeal Nos. 3634-37 of 2023)',
//   },
//   {
//     label:
//       'What are the issues in the case Sirajudheen versus Zeenath & Others(Civil Appeal No. 1491 of 2023 ?',
//     value:
//       'What are the issues in the case Sirajudheen versus Zeenath & Others(Civil Appeal No. 1491 of 2023 ?',
//   },
//   {
//     label:
//       'What are the list of Citations and other references in case of Central Bureau of Investigation Versus Kapil Wadhawan & Another.Criminal Appeal No. 391 of 2024?',
//     value:
//       'What are the list of Citations and other references in case of Central Bureau of Investigation Versus Kapil Wadhawan & Another.Criminal Appeal No. 391 of 2024?',
//   },
//   {
//     label:
//       'What is the Summary or background of the case of Dinesh Gupta Versus The State of Uttar Pradesh & Anr Criminal Appeal No(s). 214 of 2024?',
//     value:
//       'What is the Summary or background of the case of Dinesh Gupta Versus The State of Uttar Pradesh & Anr Criminal Appeal No(s). 214 of 2024?',
//   },
//   {
//     label:
//       'What is the Judgment or Decision or Findings of Trial Court in case of Rajendhiran versus Muthaiammal @ Muthayee & Ors, Civil Appeal No.37 of 2024?',
//     value:
//       'What is the Judgment or Decision or Findings of Trial Court in case of Rajendhiran versus Muthaiammal @ Muthayee & Ors, Civil Appeal No.37 of 2024?',
//   },
//   {
//     label:
//       'What is the Judgment or Decision or Findings of the Supreme Court of India in case of Satish P. Bhatt Versus The State of Maharashtra & Anr.Criminal Appeal No.42 of 2024?',
//     value:
//       'What is the Judgment or Decision or Findings of the Supreme Court of India in case of Satish P. Bhatt Versus The State of Maharashtra & Anr.Criminal Appeal No.42 of 2024?',
//   },
//   {
//     label:
//       'What are the legal Provisions or Sections included in the case of State of NCT of Delhi versus Raj Kumar @ Lovepreet @Lovely Criminal Appeal No.43 of 2024?',
//     value:
//       'What are the legal Provisions or Sections included in the case of State of NCT of Delhi versus Raj Kumar @ Lovepreet @Lovely Criminal Appeal No.43 of 2024?',
//   },
//   {
//     label:
//       'What is the Judgment or Decision or Findings of the Supreme Court of India in case of Reliance Life Insurance Company Ltd. & Anr. Versus Jaya Wadhwani Civil Appeal No. 35 of 2024?',
//     value:
//       'What is the Judgment or Decision or Findings of the Supreme Court of India in case of Reliance Life Insurance Company Ltd. & Anr. Versus Jaya Wadhwani Civil Appeal No. 35 of 2024?',
//   },
//   {
//     label:
//       'What are the issue in the of case of Raja Gounder and Others Versus M. Sengodan and Others Civil Appeal No. 600 of 2024?',
//     value:
//       'What are the issue in the of case of Raja Gounder and Others Versus M. Sengodan and Others Civil Appeal No. 600 of 2024?',
//   },
//   {
//     label:
//       'What are the list of Citations and other references in case of Mariam Fasihuddin & Another. v. State by Adugodi Police Station & Another.Criminal Appeal No. 335 of 2024?',
//     value:
//       'What are the list of Citations and other references in case of Mariam Fasihuddin & Another. v. State by Adugodi Police Station & Another.Criminal Appeal No. 335 of 2024?',
//   },
//   {
//     label:
//       'What is the Judgment or Decision or Findings of the High Court in case of Gurdev Singh Bhalla Versus State of Punjab and Others Criminal Appeal No. 120 of 2024?',
//     value:
//       'What is the Judgment or Decision or Findings of the High Court in case of Gurdev Singh Bhalla Versus State of Punjab and Others Criminal Appeal No. 120 of 2024?',
//   },
//   {
//     label:
//       'What is the Summary or background of the case of Delhi Development Authority Versus Hello Home Education Society Civil Appeal Nos. 3659-3660 of 2023?',
//     value:
//       'What is the Summary or background of the case of Delhi Development Authority Versus Hello Home Education Society Civil Appeal Nos. 3659-3660 of 2023?',
//   },
//   {
//     label:
//       'What is the Summary or background of the case DBS Bank Limited Singapore versus Ruchi Soya Industries Limited and Another Civil Appeal No. 9133 of 2019?',
//     value:
//       'What is the Summary or background of the case DBS Bank Limited Singapore versus Ruchi Soya Industries Limited and Another Civil Appeal No. 9133 of 2019?',
//   },
// ]

export const CriminalJudgesCategories = [
  {
    label: 'Bhushan Ramkrishna Gavai',
    value: 'bhushan ramkrishna gavai',
  },
  {
    label: 'Sanjiv Khanna',
    value: 'sanjiv khanna',
  },
]

export const CriminalCourtsCategories = [
  {
    label: 'Supreme Court',
    value: 'supreme court',
  },
  {
    label: 'High Courts',
    value: 'high courts',
  },
  {
    label: 'Courts of Session',
    value: 'courts of session',
  },
  {
    label: 'Judicial and Executive Magistrates',
    value: 'judicial and executive magistrates',
  },
]

export const BankruptcySubCategories = [
  {
    label: 'Insolvency',
    value: 'insolvency',
  },
  {
    label: 'Dissolution',
    value: 'dissolution',
  },
  {
    label: 'Restructuring',
    value: 'restructuring',
  },
]

export const BankruptcyVariableCategories = [
  {
    label: 'XYZ',
    value: 'xyz',
  },
  {
    label: 'GGG',
    value: 'ggg',
  },
]

export const BankruptcyJudgesCategories = [
  {
    label: 'Mr. Justice Aniruddha Bose',
    value: 'aniruddha bose',
  },
  {
    label: ' Mr. Justice Ajjikuttira Somaiah Bopanna',
    value: 'ajjikuttira somaiah bopanna',
  },
]

export const BankruptcyCourtsCategories = [
  {
    label: ' National Company Law Tribunal (NCLT)',
    value: 'NCLT',
  },
  {
    label: ' National Company Law Appellate  Tribunal (NCLAT)',
    value: 'NCLAT',
  },
  {
    label: 'Insolvency and Bankruptcy Board of India (IBBI)',
    value: 'IBBI',
  },
]
