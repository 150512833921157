import React from 'react'
import { Avatar, Paper, Stack, Typography, type TypographyProps, styled } from '@mui/material'
import { useSidebarContext } from '../../../../contexts/Old_SidebarContext'

const StyledLink = styled(Typography)<TypographyProps>(({ theme }) => ({
  ...theme.typography.subtitle1,
  color: theme.palette.common.lightBlue,
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
}))



const NewMessage = (): React.ReactElement | null => {
  let content = null
  const category = localStorage.getItem('category')
  const category2 = localStorage.getItem('category2')
  const category3 = localStorage.getItem('category3')

  // console.log('categories', category, category2, category3)

  const civilCases = ['How to deal with a Civil Case?', 'What are the Do’s and Don’ts in Civil Cases?']

  const criminalCases = ['How to deal with a Criminal Case?', 'What are the Do’s and Don’ts in Criminal Cases?']

  const bankruptcyCases = ['How to deal with a Bankruptcy Case?', 'What are the Do’s and Don’ts in Bankruptcy Cases?']

  // console.log('categories []', [...civilCases, ...criminalCases, ...bankruptcyCases])


  // alert('quesiton outside prompt ' + question)

  const { sendData, setMessage, setInputMessage } = useSidebarContext()

  // const handleClick = () => {
  // aiResponse()
  // sendData()
  //   setMessage('')
  // }

  if (category === 'Civil Cases' && category2 === 'Criminal Cases' && category3 === 'Bankruptcy Cases') {
    content = [...civilCases, ...criminalCases, ...bankruptcyCases].map((que, index) => {
      return <StyledLink key={index}
        onClick={() => {
          // handleClick()
          setInputMessage(que)
          // setMessage('How to deal with a Civil Case?')
        }}
      >
        {que}
      </StyledLink >
    })

  } else if (category === 'Civil Cases' && category3 === 'Bankruptcy Cases') {
    content = [...civilCases, ...bankruptcyCases].map((que, index) => {
      return <StyledLink key={index}
        onClick={() => {
          // handleClick()
          setInputMessage(que)
          // setMessage('How to deal with a Civil Case?')
        }}
      >
        {que}
      </StyledLink >
    })

  } else if (category === 'Civil Cases' && category2 === 'Criminal Cases') {
    content = [...civilCases, ...criminalCases].map((que, index) => {
      return <StyledLink key={index}
        onClick={() => {
          // handleClick()
          setInputMessage(que)
          // setMessage('How to deal with a Civil Case?')
        }}
      >
        {que}
      </StyledLink >
    })

  } else if (category2 === 'Criminal Cases' && category3 === 'Bankruptcy Cases') {
    content = [...criminalCases, ...bankruptcyCases].map((que, index) => {
      return <StyledLink key={index}
        onClick={() => {
          // handleClick()
          setInputMessage(que)
          // setMessage('How to deal with a Civil Case?')
        }}
      >
        {que}
      </StyledLink >
    })

  } else if (category === 'Civil Cases') {
    content = civilCases.map((que, index) => {
      return <StyledLink key={index}
        onClick={() => {
          // handleClick()
          setInputMessage(que)
          // setMessage('How to deal with a Civil Case?')
        }}
      >
        {que}
      </StyledLink >
    })

  } else if (category === 'Criminal Cases') {
    content = criminalCases.map((que, index) => {
      return <StyledLink key={index}
        onClick={() => {
          // handleClick()
          setInputMessage(que)
          // setMessage('How to deal with a Civil Case?')
        }}
      >
        {que}
      </StyledLink >
    })

  } else if (category === 'Bankruptcy Cases') {
    content = bankruptcyCases.map((que, index) => {
      return <StyledLink key={index}
        onClick={() => {
          // handleClick()
          setInputMessage(que)
          // setMessage('How to deal with a Civil Case?')
        }}
      >
        {que}
      </StyledLink >
    })
  }

  return (
    <Paper
      elevation={0}
      sx={{
        color: '#777',
        border: '2px solid green',
        p: 2,
        pb: 3,
        display: 'flex',
        alignItems: 'flex-start',
      }}
    >
      <Avatar variant='square'>LT</Avatar>
      <Stack spacing={1.5} px={2}>
        <Typography variant='subtitle2' color='textSecondary'>
          Hello! Heard that you’re killing it in the courtroom. Need assistance? Ask and you shall
          receive.
        </Typography>
        <Typography variant='subtitle2' color='textSecondary'>
          You can try the following prompts:
        </Typography>

        {/* <StyledLink>How to deal with a Civil Case?</StyledLink>
        <StyledLink>What are the Do’s and Don’ts in Civil Cases?</StyledLink> */}
        {content}
      </Stack>
    </Paper>
  )
}

export default NewMessage
