import React, { useState } from 'react'
import phoneGif from '../../../assets/animations/phoneGif.gif'
import { Box, Button, Grid, Typography } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { type InferType } from 'yup'
// import axios from 'axios'
import TextField from '../../../components/shared/TextField'
import { contactSchema } from '../../../lib/validation'
import SelectField from '../../../components/shared/SelectField'
import Countries from '../../../utils/Countries.json'
import baseUrl from '../../../config/baseUrl'
import { useSnackbar } from '../../../components/SnackbarProvider'
interface Props {
  // onSubmit: (values: FormData) => void
  setSuccessfullyDialog: any
}

type FormData = InferType<typeof contactSchema>

const ContactForm = ({ setSuccessfullyDialog }: Props) => {
  const {
    control,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(contactSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      contactNo: '',
      email: '',
      zipCode: '',
      country: 'IN',
      message: '',
    },
  })

  const values = {
    firstName: '',
    lastName: '',
    contactNo: '',
    email: '',
    zipCode: '',
    country: 'IN',
    message: '',
  }

  const [formData, setFromData] = useState(values)
const showSnackbar =useSnackbar()
  const handleChange = (e: any) => {
    const { name, value } = e.target
    setFromData({ ...formData, [name]: value })
  }

  const submitForm = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/contact/addcontact`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          FirstName: formData.firstName,
          LastName: formData.lastName,
          Email: formData.email,
          ContactNo: formData.contactNo,
          ZipCode: formData.zipCode,
          Country: formData.country,
          Description: formData.message,
        }),
      })

      const data = await response.json()

      if (data.status === true) {
        setFromData(values)
        setSuccessfullyDialog(true)
      }else{
        showSnackbar('Something went wrong, Please try again','error')
      }
    } catch (error: any) {
      // console.log('data cannot be send error: ', error.message)
      showSnackbar(error,'error')
    }
    setFromData(values) // Add the missing closing parenthesis here
  }

  return (
    <>
      <form onSubmit={handleSubmit(submitForm)}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', gap: 5 }}>
          <Grid container rowSpacing={3} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={6}>
              <Controller
                name='firstName'
                control={control}
                render={({ field: { name, onChange, value } }) => (
                  <TextField
                    name={name}
                    // onChange={onChange}
                    onChange={(e) => {
                      onChange(e)
                      // setFromData({ ...formData, firstName: e.target.value })
                      handleChange(e)
                    }}
                    value={formData.firstName}
                    error={errors.firstName?.message}
                    label='First Name'
                    placeholder='Enter First Name'
                    isRequired
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name='lastName'
                control={control}
                render={({ field: { name, onChange, value } }) => (
                  <TextField
                    name={name}
                    onChange={(e) => {
                      onChange(e)
                      // setFromData({ ...formData, firstName: e.target.value })
                      handleChange(e)
                    }}
                    value={formData.lastName}
                    error={errors.lastName?.message}
                    label='Last Name'
                    placeholder='Enter Last Name'
                    isRequired
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name='email'
                control={control}
                render={({ field: { name, onChange, value } }) => (
                  <TextField
                    name={name}
                    // onChange={onChange}
                    onChange={(e) => {
                      onChange(e)
                      // setFromData({ ...formData, firstName: e.target.value })
                      handleChange(e)
                    }}
                    value={formData.email}
                    error={errors.email?.message}
                    label='Email'
                    placeholder='Enter email'
                    isRequired
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name='contactNo'
                control={control}
                render={({ field: { name, onChange, value } }) => (
                  <TextField
                    name={name}
                    // onChange={onChange}
                    onChange={(e) => {
                      onChange(e)
                      // setFromData({ ...formData, firstName: e.target.value })
                      handleChange(e)
                    }}
                    value={formData.contactNo}
                    label='Contact No.'
                    placeholder='Enter Contact No.'
                    error={errors.contactNo?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name='zipCode'
                control={control}
                render={({ field: { name, onChange, value } }) => (
                  <TextField
                    name={name}
                    // onChange={onChange}
                    onChange={(e) => {
                      onChange(e)
                      // setFromData({ ...formData, firstName: e.target.value })
                      handleChange(e)
                    }}
                    value={formData.zipCode}
                    label='Zip Code'
                    placeholder='Enter Zip Code'
                    error={errors.zipCode?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name='country'
                control={control}
                render={({ field: { name, onChange, value } }) => (
                  <SelectField
                    label='Country'
                    isRequired
                    placeholder='Select country'
                    name={name}
                    value={formData.country}
                    options={Countries}
                    // onChange={onChange}
                    onChange={(e) => {
                      onChange(e)
                      // setFromData({ ...formData, firstName: e.target.value })
                      handleChange(e)
                    }}
                    error={errors.country?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name='message'
                control={control}
                render={({ field: { name, onChange, value } }) => (
                  <TextField
                    name={name}
                    // onChange={onChange}
                    isRequired
                    onChange={(e) => {
                      onChange(e)
                      // setFromData({ ...formData, firstName: e.target.value })
                      handleChange(e)
                    }}
                    value={formData.message}
                    label='Description'
                    placeholder='Enter Your message'
                    multiline
                    rows={4}
                    error={errors.message?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={7} sm={8} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
              <Button type='submit' variant='contained' sx={{ py: 1.5 }}>
                Send Query
              </Button>
            </Grid>
            <Grid item xs={5} sm={4} sx={{
              display: 'flex',
              flexDirection:'column',
              justifyContent: { xs: 'flex-end', sm: 'flex-start' },
              alignItems: 'flex-start'
            }}>
<Box sx={{
  display:'flex',
  justifyContent:{xs:'flex-end',sm:'flex-start'},
  alignItems:'flex-end'
}}>
<Typography variant='body2' sx={{fontSize:'12px',mr:'4px'}} >For any query</Typography>


              <img src={phoneGif} alt='Call' width='40px' height='auto' />
              </Box>
              <Typography sx={{ fontSize: { xs: '1rem', md: '1.25rem' } }}>
                7506348004
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </form ></>
  )
}

export default ContactForm
