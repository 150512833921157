import React, { useEffect } from 'react'
import { Dialog, DialogContent, Typography, IconButton, Button } from '@mui/material'
// import { CloseIcon } from '../../Icons'
import { useLocation } from 'react-router-dom'
import baseUrl from '../../../config/baseUrl'
import { useSnackbar } from '../../SnackbarProvider'
const PaymentSuccessfulDialog = () => {
  const location = useLocation()
  console.log('location', location)
  const searchParams = new URLSearchParams(location.search)
  console.log('searchParams', searchParams)
  const orderId = searchParams.get('order_id')
  console.log('orderId', orderId)
  const showSnackbar = useSnackbar()

  const getOrderDetails = async (orderNumber: any) => {
    try {
      const getOrderDetailsResponse = await fetch(
        `${baseUrl}/api/order/getCallBack?order_id=${orderNumber as string}`,
      )

      console.log('getOrderDetailsResponse', getOrderDetailsResponse)

      const getOrderDetailsResponseData = await getOrderDetailsResponse.json()

      console.log('getOrderDetailsResponseData', getOrderDetailsResponseData)

      if (getOrderDetailsResponse.ok) {
        if (getOrderDetailsResponseData?.order?.order_status === 'PAID') {
          sessionStorage.setItem('isPaid', 'true')
          localStorage.setItem('isPaid', 'true')

          sessionStorage.setItem('orderDate', getOrderDetailsResponseData?.order?.updatedAt)
          localStorage.setItem('orderDate', getOrderDetailsResponseData?.order?.updatedAt)
        }
      }
    } catch (error: any) {
      showSnackbar(error?.message, 'error')
    }
  }

  useEffect(() => {
    document.title = 'Payment successful - LawTech'
  }, [])

  useEffect(() => {
    if (orderId) {
      getOrderDetails(orderId)
    }
  }, [orderId])

  const handleClose = () => {
    // navigate('/NewChat')

    // window.location.replace(`${baseUrl}/NewChat`)
    window.close()
    if (window.opener && !window.opener.closed) {
      window.opener.close()
    }
  }

  return (
    <Dialog PaperProps={{ sx: { maxWidth: 407 } }} open={true}>
      <IconButton
        disableRipple
        sx={{
          position: 'absolute',
          top: 16,
          right: 16,
          color: 'text.secondary',
          p: 0,
          width: 20,
          height: 20,
        }}
      >
        {/* <CloseIcon /> */}
      </IconButton>
      <DialogContent
        sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 3, p: 6 }}
      >
        <Typography align='center'>Payment Successful</Typography>

        <Typography align='center'>Payment: {orderId}</Typography>

        <Button variant='contained' color='primary' onClick={handleClose}>
          Continue
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default PaymentSuccessfulDialog
