import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Grid,
  Box,
  Typography,
  Button,
  Stack,
  Divider,
  Stepper,
  Step,
  StepLabel,
  IconButton
} from '@mui/material'

import { GoogleOutlinedIcon, LinkedinIcon } from '../../../components/Icons'
import { useSnackbar } from '../../../components/SnackbarProvider'
import RegisterForm from './RegisterForm'
import Categories from './Categories'
import { SuccessfullyDialog } from '../../../components/shared/Dialogs'
import { useAuthContext } from '../../../contexts/AuthContext'
import { useGoogleLogin } from '@react-oauth/google'
import { useLinkedIn } from 'react-linkedin-login-oauth2'
import EmailVerification from '../EmailVerification'
import baseUrl from '../../../config/baseUrl'
import SquareLogo from '../../../assets/images/Lawtech icon square.png'
const RegisterIndex = () => {
  const navigate = useNavigate()
  const { setAuthenticated } = useAuthContext()
  const [activeStep, setActiveStep] = useState<any>(0)
  const [successfullyDialog, setSuccessfullyDialog] = useState<any>(false)
  const [backotp, setBackotp] = useState<any>('')
  const [buttonClicked, setButtonClicked] = useState(false)
  const [registrationDetail, setRegistrationDetails] = useState<any>(null)
  const [tcAccepted, setTcAccepted] = useState<boolean>(false)
  const [userData, setUserData] = useState({ firstName: '', lastName: '', email: '', gid: '' })
  // const [googleData, setGoogleData] = useState([])
const showSnackbar = useSnackbar()
  useEffect(() => {
    document.title = 'Register - LawTech'
  }, [])


  useEffect(() => {
    // This useEffect will be triggered whenever `userData` changes.
    if (userData.firstName && userData.lastName && userData.email && userData.gid) {
      googleRagister() // Call googleRagister when `userData` is updated.
    }
  }, [userData])

  const handleNextStep = () => {
    setActiveStep(1)
  }

  const handleEmailVerificationSuccess = () => {
    setActiveStep(2) // Move to the next step (step 2 of registration)
  }

  const googleRagister = async () => {
    try {
      const googleRagisterResponse = await fetch(`${baseUrl}/api/auths/googlesignup1`, {
        method: 'POST',
        mode: 'cors',

        // mode: 'no-cors',

        headers: {
          'Content-Type': 'application/json',
        },

        body: JSON.stringify({
          FirstName: userData.firstName,
          LastName: userData.lastName,
          Email: userData.email,
          GoogleId: userData.gid,
        }),
      })

      const googleRagisterResponseData = await googleRagisterResponse.json()

      if (googleRagisterResponseData.status === true) {
        handleNextStep()
        setUserData({
          firstName: '',
          lastName: '',
          email: '',
          gid: '',
        })

        // console.log('googleRagisterResponseData : ', googleRagisterResponseData)
        sessionStorage.setItem('userId', googleRagisterResponseData?.user?.id)
        localStorage.setItem('userId', googleRagisterResponseData?.user?.id)
        setTimeout(() => {
          // intentional delay
        }, 300)
        googleRagisterResponseData.status && setActiveStep(2)
      } else {
        const errMsg = googleRagisterResponseData?.message
       showSnackbar(errMsg, 'info')
        // console.log('something went wrong during signing')
      }
    } catch (error: any) {
      // console.log('google register Error : ', error.message)
      showSnackbar(error?.message, 'error')
    }
  }

  // const handleCompleted = (selectedValues: string) => {
  //   setSuccessfullyDialog(true)
  // }

  const handleClose = () => {
    setSuccessfullyDialog(false)
    setAuthenticated(true)
    // navigate('/')
    navigate('/auth/login')
  }

  const signIn = useGoogleLogin({
    // clientId: '566567418175-e6d37md2ccqfcjs2hgptlu8p5sqr2v5m.apps.googleusercontent.com', // Your Google OAuth client ID

    onSuccess: async (response: any) => {
      // Handle the Google login response here
      // const data = await JSON.stringify(response)
      const googleToken = response.access_token

      const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
        headers: {
          Authorization: `Bearer ${googleToken as string}`,
        },
      })

      const userInfoData = await userInfoResponse.json()

      if (userInfoResponse.ok) {
        setUserData({
          firstName: userInfoData.given_name,
          lastName: userInfoData.family_name,
          email: userInfoData.email,
          gid: userInfoData.id,
        })
      }
      sessionStorage.setItem('googleId', userInfoData.id)
    },
    onError: (error: any) => {
      // Handle login failure here
      const errorMessage = (error as Error)?.message || 'Google login failed';
  showSnackbar(`Google login failed: ${errorMessage}`, 'error');
    },
  })
  const signupGoogle = () => {
    if (!tcAccepted) {
      showSnackbar('Please read & accecpt the terms & conditions','success')
    } else {
      signIn()
      setButtonClicked(true) // User clicked a button
    }
  }



  const { linkedInLogin } = useLinkedIn({
    clientId: '86vhj2q7ukf83q',
    redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: (code) => {
      // console.log(code)
    },
    onError: (error: any) => {
      // console.log(error)
      showSnackbar(error, 'error')
    },
  })

  return (
    <>
      <Grid item xs={12} md={7} sx={{ overflowY: { xs: 'hidden', sm: 'auto' }, height: { xs: 'auto', sm: '100dvh' }, width: '100%' }}>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            height: '100%',
            py: 2,

          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: { xs: 'center', md: 'flex-start' },
              width: '100%',
              maxWidth: 644,
              m: '10px auto',
              gap: 2,
              minHeight: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                gap: 2.5
              }}
            >
              {/* <AppLogoIcon sx={{ width: 91, height: 63 }} /> */}
              <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'center', sm: 'flex-start' }, justifyContent: 'center', }}>
                <IconButton style={{ cursor: 'pointer' }} onClick={() => { navigate('/') }}>
                  <img src={SquareLogo} alt='' height='60px' />
                </IconButton>
                {activeStep !== 1 && (
                  <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', width: '100%', ml: { xs: 0, sm: 2 } }}>
                    <Box sx={{ flexGrow: 1, textAlign: { xs: 'center', sm: 'left' } }}>
                      <Typography variant='h3'>Welcome!!</Typography>
                      {!activeStep && (
                        <Typography variant='body1'>Register to LawTech Platform</Typography>
                      )}
                      {!!activeStep && (
                        <Typography variant='body1'>Select a Jurisdiction Category</Typography>
                      )}
                    </Box>

                  </Box>
                )}
              </Box>
              <Box sx={{ flexGrow: 1, mt: { xs: 2, sm: 0 }, width: '250px', maxWidth: '250px', justifySelf: 'flex-end' }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  <Step>
                    <StepLabel>Step 1</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Step 2</StepLabel>
                  </Step>
                </Stepper>
              </Box>
            </Box>

            {!activeStep && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 1,
                    width: '100%',
                  }}
                >
                  <Stack direction='row' spacing={3} width='100%'>
                    <Button
                      variant='contained'
                      sx={{
                        maxWidth: '100%',
                        backgroundColor: 'background.light',
                        color: 'text.secondary',
                        '&:hover, &:active, &:focus': {
                          backgroundColor: 'background.light',
                        },
                      }}
                      endIcon={<GoogleOutlinedIcon />}
                      onClick={signupGoogle}
                    >
                      Google
                    </Button>
                    <Button
                      onClick={linkedInLogin}
                      variant='contained'
                      sx={{
                        maxWidth: '100%',

                        backgroundColor: 'background.light',
                        color: 'text.secondary',
                        '&:hover, &:active, &:focus': {
                          backgroundColor: 'background.light',
                        },
                      }}
                      endIcon={<LinkedinIcon />}
                    >
                      LinkedIn
                    </Button>
                  </Stack>
                  <Divider sx={{ width: '80%' }}>
                    <Typography variant='subtitle1'>or continue with</Typography>
                  </Divider>
                </Box>
                <Box sx={{ width: '100%', pb: 5 }}>
                  <RegisterForm
                    tcAccepted={tcAccepted}
                    setTcAccepted={setTcAccepted}
                    setStep={setActiveStep}
                    setBackotp={setBackotp}
                    setRegistrationDetails={setRegistrationDetails}
                    registrationDetail={registrationDetail}
                  />
                </Box>
              </>
            )}

            {/* {activeStep === 1 && (
              <EmailVerification onSuccess={handleEmailVerificationSuccess} />
            )} */}

            {!buttonClicked && activeStep === 1 && (
              <EmailVerification
                onSuccess={handleEmailVerificationSuccess}
                backotp={backotp}
                registrationDetail={registrationDetail}
              />
            )}
            {activeStep === 2 && (
              <Categories setDialog={setSuccessfullyDialog} />
              // Add components for step 2 (e.g., additional registration details)
            )}
          </Box>
        </Box>
        <SuccessfullyDialog open={successfullyDialog} onClose={handleClose} />
      </Grid>
      
    </>
  )
}

export default RegisterIndex
