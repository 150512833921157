import React, { useRef, useState } from 'react'
import baseUrl from '../../../../config/baseUrl'
import {
  Box,
  TextField,
  InputAdornment,
  Tooltip,
  IconButton,

} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { SearchIcon } from '../../../../components/Icons'
import { useSnackbar } from '../../../../components/SnackbarProvider'
import { useSidebarContext } from '../../../../contexts/Old_SidebarContext'

const SearchInput = () => {
  const searchRef = useRef()
  const { setUserHistory, setSearchValue } = useSidebarContext()
  const [searchText, setSearchText] = useState<string>('') // State to store user input
  const [showIcon, setShowIcon] = useState(false)
  const userId = localStorage.getItem('userId')
  // const { searchText, setSearchText } = useSidebarContext()
  const showSnackbar = useSnackbar()
  const handleClick = () => {

  }

  const handleChange = (e: any) => {
    if (e.target.value === '') {
      setShowIcon(false)
    }
    setSearchText(e.target.value)
    setShowIcon(true)
  }

  const handleEnterKeyPress = async (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSearchChange()
    }
    if (event.key === 'Backspace' || event.key === 'Delete') {
      if (searchText.length === 0) {
        handleClearSearch()
      }
    }
  }

  const handleSearchChange = async () => {
    if (searchText?.length === 0) {
      showSnackbar('Enter something to search!', 'info')
    }
    if (searchText?.length !== 0) {
      setSearchValue(searchText)

      try {
        const res = await fetch(`${baseUrl ?? ''}/api/users/FindHistoryAll/${userId ?? ''}`);
        // console.log('handleSearchChange res', res)
        const data = await res.json();
        // console.log('handleSearchChange', data);
        if (data?.status) {
          setUserHistory(data?.data);
        }
        if (!res.ok) {
          showSnackbar(data?.message,'info');
        }
      } catch (error: any) {
        // console.log(error);
        showSnackbar(error?.message,'info');
      }
    }
  }

  const handleClearSearch = async () => {
    setSearchText('')
    setSearchValue('')
    setShowIcon(false)
    try {
      const res = await fetch(`${baseUrl ?? ''}/api/users/FindHistoryAll/${userId ?? ''}`);
      const data = await res.json();
      if (data?.status) {
        setUserHistory(data?.data);
      }
      if (!res.ok) {
        showSnackbar(data?.message,'info');
      }
    } catch (error) {
      showSnackbar(error as string,'info');
    }
  }

  return (
    <Box style={{ position: 'relative', margin: 0, padding: 0, boxSizing: 'border-box' }}>
      <TextField
        style={{ padding: 0, marginTop: '10px' }}
        sx={{
          '& .MuiOutlinedInput-root': {
            paddingLeft: 1, paddingRight: 1
          },
          '& .MuiOutlinedInput-root .MuiInputBase-input': {
            paddingLeft: '5px'
          },
          '& .MuiInputBase-input::placeholder': {
            color: '#666 !important',
            opacity: 0.4,
          }
        }}
        size='small'
        inputRef={searchRef}
        variant='outlined'
        placeholder='History search...'
        fullWidth
        value={searchText}
        autoComplete='off'
        onChange={handleChange}
        onKeyDown={handleEnterKeyPress}
        margin='normal'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start' style={{ margin: 0, padding: 0 }}>
              <IconButton onClick={handleSearchChange}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end' style={{ margin: 0, padding: 0, }}>
              {showIcon ? (
                <Tooltip title="Clear search" placement='right'>
                  <IconButton onClick={handleClearSearch}>
                    <CloseIcon style={{ color: 'red' }} />
                  </IconButton>
                </Tooltip>) : (<></>)}
            </InputAdornment>
          ),
        }}

      // value={searchQuery}
      // onChange={handleSearchChange}
      />

      {!open && <IconButton onClick={handleClick}>
        <SearchIcon />
      </IconButton>}

    </Box>
  )
}

export default SearchInput
