import React, { useState } from 'react'
import baseUrl from '../../../config/baseUrl'
import {
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Grid,
  Box,
  Dialog,
  DialogContent,
  Typography,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useSnackbar } from '../../SnackbarProvider'

interface Props {
  open: boolean
  onClose: () => void
}

const ChangePasswordDialog = ({ open, onClose }: Props) => {
  const userId = localStorage.getItem('userId')
  const token = localStorage.getItem('token')

  // const [passwordData, setpasswordData] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const showSnackbar = useSnackbar();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!currentPassword || !newPassword || !confirmPassword) {
      showSnackbar('Please fill out all fields.', 'info')
      return
    }
    const hasSpaces = /\s/ // Regular expression to check for spaces

    if (hasSpaces.test(newPassword)) {
      showSnackbar('Password should not contain spaces.', 'info')
      return
    }

    if (newPassword !== confirmPassword) {
      showSnackbar('New password and confirm password must be the same.', 'info')
      return
    }

    if (newPassword === currentPassword) {
      showSnackbar('Current and new passwords cannot be the same.', 'info')
    }
    else {
      try {
        const response = await fetch(`${baseUrl}/api/users/ChangePassword`, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            authorization: token as string,
          },
          body: JSON.stringify({
            userId,
            currentPassword: currentPassword,
            newPassword: newPassword,
          }),
        })

        const data = await response.json()

        if (response.ok) {
          showSnackbar(data.message, 'success')
          // setpasswordData('')
          setCurrentPassword('')
          setNewPassword('')
          setConfirmPassword('')
          setTimeout(() => {
            onClose()
          }, 1500)
        } else {
          showSnackbar(data.message, 'error')
        }
      } catch (e: any) {
        showSnackbar(e.message, 'error')
      }
      // Handle form submission logic here
    }
  }

  const toggleShowPassword = (field: string) => {
    switch (field) {
      case 'current':
        setShowCurrentPassword(!showCurrentPassword)
        break
      case 'new':
        setShowNewPassword(!showNewPassword)
        break
      case 'confirm':
        setShowConfirmPassword(!showConfirmPassword)
        break
      default:
        break
    }
  }

  return (
    <Dialog open={open} maxWidth='sm' fullWidth sx={{ zIndex: 10002 }}>
      <DialogContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          p: 4,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h4'>Change Password</Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit}>
              <TextField
                sx={{
                  '& fieldset': { border: 'none' },
                  mt: 2,
                }}
                variant='outlined'
                fullWidth
                label='Current Password'
                type={showCurrentPassword ? 'text' : 'password'}
                value={currentPassword}
                onChange={(e) => {
                  setCurrentPassword(e.target.value)
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => {
                          toggleShowPassword('current')
                        }}
                        edge='end'
                      >
                        {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                sx={{
                  '& fieldset': { border: 'none' },
                  mt: 2,
                }}
                variant='outlined'
                fullWidth
                label='New Password'
                type={showNewPassword ? 'text' : 'password'}
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value)
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => {
                          toggleShowPassword('new')
                        }}
                        edge='end'
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                variant='outlined'
                sx={{
                  '& fieldset': { border: 'none' },
                  mt: 2,
                }}
                fullWidth
                label='Confirm Password'
                type={showConfirmPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value)
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => {
                          toggleShowPassword('confirm')
                        }}
                        edge='end'
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box sx={{ mt: 2 }} display='flex' justifyContent='flex-end'>

                <Button
                  sx={{ mr: 3 }}
                  variant='outlined'
                  onClick={() => {
                    setCurrentPassword('')
                    setNewPassword('')
                    setConfirmPassword('')
                    onClose()
                  }}
                >
                  Cancel
                </Button>

                <Button variant='contained' color='primary' type='submit'>
                  Submit
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ChangePasswordDialog
