import React, { useContext, createContext, useState, useEffect, } from 'react'

import { useTheme, useMediaQuery } from '@mui/material'
import baseUrl, { pythonUrl } from '../config/baseUrl'
import { useSnackbar } from '../components/SnackbarProvider'
interface SidebarContextValue {

  upgradeSubscriptionDialog: boolean,
  setUpgradeSubscriptionDialog: React.Dispatch<React.SetStateAction<boolean>>,
  locationUrl: string,
  setLocationUrl: React.Dispatch<React.SetStateAction<string>>,
  uniqueString: string,
  setUniqueString: React.Dispatch<React.SetStateAction<string>>,
  uploadedFileName: string,
  setUploadedFileName: React.Dispatch<React.SetStateAction<string>>,
  isRefFileUploaded: boolean,
  setIsRefFileUploaded: React.Dispatch<React.SetStateAction<boolean>>,
  dataInRightDrawer: string,
  setDataInRightDrawer: React.Dispatch<React.SetStateAction<string>>,
  openVerifier: boolean,
  setOpenVerifier: React.Dispatch<React.SetStateAction<boolean>>,
  historyClicked: boolean,
  setHistoryClicked: React.Dispatch<React.SetStateAction<boolean>>,
  newChatClicked: boolean,
  setNewChatClicked: React.Dispatch<React.SetStateAction<boolean>>,
  sendBtnClicked: boolean,
  setSendBtnClicked: React.Dispatch<React.SetStateAction<boolean>>,
  isLoggedIn: boolean,
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>,
  answerToLeftDrawer: string,
  setAnswerToLeftDrawer: React.Dispatch<React.SetStateAction<string>>,
  isQuestionUpdating: boolean
  setIsQuestionUpdating: React.Dispatch<React.SetStateAction<boolean>>
  sendAnswerToLeftDrawer: string
  setSendAnswerToLeftDrawer: React.Dispatch<React.SetStateAction<string>>

  simplifyText: (text: any) => string
  selectedTab: number
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>

  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  lastAskedQuestion: string
  setLastAskedQuestion: React.Dispatch<React.SetStateAction<string>>
  lastAnsweredQuestion: string
  setLastAnsweredQuestion: React.Dispatch<React.SetStateAction<string>>
  globalTokenConsumed: any
  setGlobalTokenConsumed: React.Dispatch<React.SetStateAction<any>>
  sidebarOpen: boolean
  setSidebar: React.Dispatch<React.SetStateAction<boolean>>
  aiStorage: any[]
  setAiStorage: React.Dispatch<React.SetStateAction<any[]>>
  inputMessage: string
  setInputMessage: React.Dispatch<React.SetStateAction<string>>
  answer: string
  setAnswer: React.Dispatch<React.SetStateAction<string>>

  userStatus: (userId: number) => Promise<any>
  handleSearchCount: (inputQuestion: string, userId: number) => Promise<any>
  deleteUniqueString: (text: string) => Promise<void>
  sendData: (inputQuestion: string) => Promise<void>
  getHistory: (userId: number) => Promise<any>

  message: string // Add message property to the interface
  setMessage: React.Dispatch<React.SetStateAction<string>> // Add setMessage property to the interface
  getUserHistory: (userId: number) => Promise<void>
  userHistory: any[]
  setUserHistory: React.Dispatch<React.SetStateAction<any[]>>
  editHistoryTItleAPi: (title: any, historyId: any) => Promise<void>

  setIsResponseComplete: React.Dispatch<React.SetStateAction<boolean>>
  allHistory: any[]
  setAllHistory: React.Dispatch<React.SetStateAction<any[]>>
  threadId: any // Update the type to allow null values
  setThreadId: React.Dispatch<React.SetStateAction<any>> // Add setMessage property to the interface
  clearAllHistory: () => Promise<void>

  buttonsDisabled: boolean
  setButtonsDisabled: React.Dispatch<React.SetStateAction<boolean>>

  // Message Context Functionality
  messages: string[]
  activeMessageContext: number
  setActiveMessageContext: React.Dispatch<React.SetStateAction<number>>

  // Search History Functionality

  searchValue: any
  setSearchValue: React.Dispatch<React.SetStateAction<any>>

  // Edit Question
  fetchServerResponse: (question: string) => Promise<boolean>
  editQuestionList: any[]
  setQuestionId: any
  userInput: any
  setUserInput: React.Dispatch<React.SetStateAction<string>> // Corrected property name

  // thredId Deleted

  isThredIdDelete: boolean
  setIsThreadIdDelete: React.Dispatch<React.SetStateAction<boolean>>
  getUserHistorybyThreadId: (tId: string) => Promise<void>
}

const messages = ['']


const SidebarContext = createContext<SidebarContextValue | undefined>(undefined);

interface Props {
  children: React.ReactNode
}
export const SidebarProvider = ({ children }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const userId: number = localStorage.getItem('userId') as unknown as number
  const token = localStorage.getItem('token')
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [answerToLeftDrawer, setAnswerToLeftDrawer] = useState('')
  const [sendBtnClicked, setSendBtnClicked] = useState(false)
  const [newChatClicked, setNewChatClicked] = useState(false)
  const [historyClicked, setHistoryClicked] = useState(false)
  const [openVerifier, setOpenVerifier] = useState(false)
  const [dataInRightDrawer, setDataInRightDrawer] = useState('')
  const [isRefFileUploaded, setIsRefFileUploaded] = useState(false)
  const [uploadedFileName, setUploadedFileName] = useState('')
  const [uniqueString, setUniqueString] = useState('')
  const [locationUrl, setLocationUrl] = useState('')
  const [upgradeSubscriptionDialog, setUpgradeSubscriptionDialog] = useState(false)


  const [isQuestionUpdating, setIsQuestionUpdating] = useState<boolean>(false)

  const [selectedTab, setSelectedTab] = useState<number>(1)

  const [sidebarOpen, setSidebar] = useState<boolean>(!isMobile)
  const [aiStorage, setAiStorage] = useState<any[]>([])
  const [inputMessage, setInputMessage] = useState('')
  const [answer, setAnswer] = useState('')
  const [message, setMessage] = useState<string>('')

  const [responseData, setResponseData] = useState('')
  const [tokenConsumed, setTokenConsumed] = useState<string | null>(null)

  const [threadId, setThreadId] = useState<string>('') // Initialize with null

  const [userHistory, setUserHistory] = useState<any[]>([])
  const [allHistory, setAllHistory] = useState<any[]>([])

  const [isResponseComplete, setIsResponseComplete] = useState<boolean>(false) // Add this flag

  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false)

  const [isThredIdDelete, setIsThreadIdDelete] = useState<boolean>(false)

  // Message Context

  const [activeMessageContext, setActiveMessageContext] = useState<number>(0)

  // Search Histroy
  const [searchValue, setSearchValue] = useState<any>('') // State to store user input

  // for token consumption count

  const [globalTokenConsumed, setGlobalTokenConsumed] = useState<any>(null)
  const [lastAskedQuestion, setLastAskedQuestion] = useState('')
  const [lastAnsweredQuestion, setLastAnsweredQuestion] = useState('')
  // For Pagination

  const [userInput, setUserInput] = useState('')
  const [responseComplete, setResponseComplete] = useState(false)
  const [editQuestion, setEditQuestion] = useState('')
  const [serverResponse, setServerResponse] = useState('')
  const [questionId, setQuestionId] = useState<any>(null)
  const [editQuestionList, setEditQuestionList] = useState<any[]>([])

  // loader
  const [isLoading, setIsLoading] = useState(false)
  const [sendAnswerToLeftDrawer, setSendAnswerToLeftDrawer] = useState('')
const showSnackbar = useSnackbar()

  useEffect(() => {
    userId && getUserHistory(userId)
  }, [])

  // useEffect(() => {
  //   if (threadId) {
  //     navigate('/NewChat/' + threadId)
  //   }
  // }, [threadId])

  useEffect(() => {
    // console.log('inside sidebar sendAnswerToLeftDrawer', sendAnswerToLeftDrawer)
  }, [sendAnswerToLeftDrawer])

  useEffect(() => {
    if (threadId !== '' && threadId !== null && threadId !== undefined) {
      getUserHistorybyThreadId(threadId)
    }
  }, [threadId, aiStorage, questionId])

  useEffect(() => {
    if (isThredIdDelete) {
      threadId !== '' &&
        threadId !== null &&
        threadId !== undefined &&
        getUserHistorybyThreadId(threadId)
    }
    setIsResponseComplete(false)
  }, [isThredIdDelete])

  useEffect(() => {
    // if (isResponseComplete) {
    //   postHistory()
    // }
    setIsResponseComplete(false)
  }, [isResponseComplete])

  useEffect(() => {
    if (responseComplete) {
      storeEditedHistory()
    }

    setResponseComplete(false)
  }, [responseComplete])

  const userStatus = async (userId: number) => {
    // setMessage(inputQuestion)
    // alert('inside userStatus')

    try {
      const response = await fetch(`${baseUrl}/api/admin/checkuserstatus/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: token as string,
        },
        body: JSON.stringify({
          Promptquery: message,
        }),
      })

      const responseData = await response.json()
      // console.log('userStatus responseData :', responseData)
      return responseData
      // responseData?.status && handleSearchCount()
    } catch (error: any) {
      setIsLoading(false)
      // console.log('Error in userStatus API :', error.message)
      showSnackbar(error.message,'error')
      
      return error
    }
  }

  const handleSearchCount = async (inputQuestion: string, userId: number) => {
    // alert('inside handleSearchCount')
    try {
      const handleSearchCountResponse = await fetch(`${baseUrl}/api/users/search`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          UserId: userId,
        }),
      })

      const handleSearchCountResponseData = await handleSearchCountResponse.json()

      // console.log('handleSearchCountResponse', handleSearchCountResponse)

      if (!handleSearchCountResponse?.ok) {
        setIsLoading(false)
        showSnackbar(handleSearchCountResponseData?.message,'error')
        return false
      }

      if (handleSearchCountResponse?.ok) {
        setIsLoading(false)
        return true
        // console.log('inputQuestion: string, before sendmsg', inputQuestion)
        // inputQuestion && sendData(inputQuestion)
      }
    } catch (error: any) {
      // console.log('Error in searchount api :', error.message)
      showSnackbar(error?.message,'error')
    }
  }

  const deleteUniqueString = async (text: string) => {
    // console.log('inside deleteUniqueString',text)
    fetch(`${baseUrl}/pyapi/delete_vectordb/${uniqueString}`, {
      method: 'DELETE'
    })
      .then(async (response) => {
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'An error occurred');
        }
        const data = await response.json();
        const responseData = (data.message);  // Assuming the backend sends a success message
        const responseStatusText = 'File and associated data successfully deleted!'

        setUniqueString('');  // Clear the unique string after deletion
        return {
          responseData, responseStatusText
        }
      })
      .catch((error) => {
        return error.message;
      });

  }

  const sendData = async (inputQuestion: string) => {
    // alert('inside sendData')
    // let chunks = ''
    let modifiedString = ''

    if (inputQuestion) {
      if (isRefFileUploaded && uniqueString) {
        // console.log('inside isRefFileUploaded', isRefFileUploaded, uniqueString)
        // when file is uplaoded
        try {
          setLastAskedQuestion(inputQuestion)
          const formData = new FormData()

          formData.append('uniqueString', uniqueString)
          // setInputMessage('')
          const response = await fetch(`${baseUrl}/pyapi/mainqa`, {
            method: 'POST',
            headers: {
              usecase: 'qa',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ question: inputQuestion, uniqueString }),
          })

          if (!response.ok) {

            // const err = await response.json()
            throw new Error('Request failed')
          }

          // console.log('inside isRefFileUploaded ok', response)
          const responseData = await response.json()

          // console.log('inside isRefFileUploaded responseData', responseData)

          if (responseData?.answer) {
            // const reader = response.body.getReader()
            // console.log('responseData', responseData)

            // while (true) {
            //   const { done, value } = await reader.read()
            //   if (done) {
            //     setIsResponseComplete(true)

            //     break
            //   }
            //   chunks += new TextDecoder().decode(value)
            // }

            // const match = chunks.match(/total_tokens_consumed":(\d+)/)
            // const totalTokensConsumed = match ? match[1] : null
            setTokenConsumed(responseData?.total_tokens_consumed)
            // setGlobalTokenConsumed(totalTokensConsumed)
            // console.log('chunks', chunks)
            setResponseData(responseData?.answer)

            // const data = await response.json()
            // console.log('data doubtful', data)

            modifiedString = simplifyText(responseData?.answer)

            // modifiedString && console.log('modifiedString12', modifiedString)

            modifiedString && setMessage(modifiedString)
            modifiedString && setAnswer(modifiedString)
            modifiedString && setLastAnsweredQuestion(modifiedString)

            postHistory(
              inputQuestion,
              modifiedString,
              userId,
              threadId,
              1000,
              // tokenConsumed:responseData?.total_tokens_consumed,
            )
            modifiedString = ''
            setIsLoading(false)
          }
        } catch (error) {
          showSnackbar('An error occurred while submitting the data', 'error')
        }
      } else {
        // when file is not uploaded
        try {
          setLastAskedQuestion(inputQuestion)
          // setInputMessage('')

          const response = await fetch(`${pythonUrl}/pyapi/search`, {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ Promptquery: inputQuestion }),
          })

          if (!response.ok) {
            throw new Error('Request failed')
          }

          const responseData = await response.json()

          if (responseData?.result) {
            // const reader = response.body.getReader()
            // console.log('responseData', responseData)

            // while (true) {
            //   const { done, value } = await reader.read()
            //   if (done) {
            //     setIsResponseComplete(true)

            //     break
            //   }
            //   chunks += new TextDecoder().decode(value)
            // }

            // const match = chunks.match(/total_tokens_consumed":(\d+)/)
            // const totalTokensConsumed = match ? match[1] : null
            setTokenConsumed(responseData?.total_tokens_consumed)
            // setGlobalTokenConsumed(totalTokensConsumed)
            // console.log('chunks', chunks)
            setResponseData(responseData?.result)

            // const data = await response.json()
            // console.log('data doubtful', data)

            modifiedString = simplifyText(responseData?.result)

            // modifiedString && console.log('modifiedString12', modifiedString)

            modifiedString && setMessage(modifiedString)
            modifiedString && setAnswer(modifiedString)
            modifiedString && setLastAnsweredQuestion(modifiedString)

            postHistory(
              inputQuestion,
              modifiedString,
              userId,
              threadId,
              responseData?.total_tokens_consumed,
            )
            modifiedString = ''
            setIsLoading(false)
          }
        } catch (error) {
          showSnackbar('An error occurred while submitting the form data', 'error')
        }
      }
    }
  }

  const getHistory = async (userId: number) => {
    try {
      const response = await fetch(`${baseUrl}/api/users/FindHistoryAll/${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: token as string,
        },
      })

      if (!response.ok) {
        throw new Error(`Failed to fetch user history: ${response.statusText}`)
      }

      const { data } = await response.json()
      const last10 = data.slice(0, 6)
      // console.log('getHistory data', last10)
      return last10
    } catch (error) {
      showSnackbar('Error fetching user history', 'error')
    }
  }

  const simplifyText = (text: string) => {
    const mapObj: Record<string, string> = {
      '\\n': '<br />',
      '{"result":"': '',
      '","total_tokens_consumed":2589}': '',
    }

    const initialText = text

    // console.log('initialText', initialText)

    const formattedText = initialText?.replace(
      /{"result":"|","total_tokens_consumed":2589}/gi,
      (matched: string): string => {
        return mapObj[matched] || matched
      },
    )

    // console.log('formattedText', formattedText)

    let modifiedString = ''

    modifiedString = formattedText?.replace('}', '')

    // modifiedString = formattedText?.replace('#', '<h1>')

    modifiedString = modifiedString?.replace('{"result":"', '')

    modifiedString = modifiedString?.replace('<ctx>', '')

    modifiedString = modifiedString?.replace('</ctx>', '')

    // console.log('modifiedString result', modifiedString)
    modifiedString = modifiedString?.replace('","total_tokens_consumed":', '')

    modifiedString = modifiedString?.replace(/\\u201c/g, ' "')

    // console.log('modifiedString /\\u201c/g', modifiedString)

    modifiedString = modifiedString?.replace(/\\u201d/g, '"')

    modifiedString = modifiedString?.replace(/\\u00b7/g, '&middot;')

    // console.log('modifiedString /\\u00b7/g', modifiedString)

    // modifiedString = modifiedString?.replace(/\\"/g, '"')

    // // modifiedString = modifiedString?.replace(/\*\*/, '<b>').replace(/\*\*/, '</b>')

    modifiedString = modifiedString?.replace(/\s*\d+\s*$/, '')

    // modifiedString = modifiedString
    //   ?.replace(/([^.\n])\\n\\n([^.\n])/g, '$1 $2')
    //   ?.replace(/\\n\\n/g, '\n')

    // console.log('modifiedString /s*d+s*$/', modifiedString)

    modifiedString = modifiedString?.replace(/\\n/g, '\n')

    modifiedString = modifiedString?.replace(/\\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')

    // console.log('modifiedString /\\t/g', modifiedString)

    modifiedString = modifiedString.replace(/\((\d+)\)/g, (match, number: string) => {
      if (Number(number) < 50) {
        return `\n(${number})` // Move captured number to the next line
      }
      return match // Keep other matches unchanged
    })

    // modifiedString = modifiedString
    //   ?.replace(/\((\w)\)/g, '\n($1)')
    //   ?.replace(/(?<!\S)(To,)(?!\S)/gi, '\n$1')
    //   ?.replace(/(_{10,})/g, '\n$1')
    //   ?.replace(/(?<!\S)(Sir,)(?!\S)/gi, '\n$1')
    //   ?.replace(/(?<!\S)(^.\d.)(?!\S)/gi, '\n$1')
    //   ?.replace(/(From: .+)/g, '\n$1\n')
    //   ?.replace(/(Date: .+)/g, '\n$1\n')
    //   ?.replace(/\s(\d+\.)/g, '\n$1')
    //   ?.replace(/\*\*([^*]+)\*\*/g, '<b>$1</b>')

    // modifiedString = modifiedString
    //   ?.replace(/\*\*(.*?)\*\*|\b\*\*(.*?)\*\*\b/, '<b>$1</b>')
    //   .replace(/([^.])(\\n\\n)/g, '')

    // const pattern = /\b\d+\.\s(?:[^.]*?(?<!\.)\s(?!.*?\.)|[^.\n])*?:/g

    // modifiedString = modifiedString.replace(pattern, '<b>$&</b>')
    // // modifiedString = String.fromCharCode(parseInt(modifiedString.substring(2), 16))

    modifiedString = decodeUnicode(modifiedString)
    // console.log('modifiedString', modifiedString)

    return modifiedString
    // return formattedText
  }

  const decodeUnicode = (str: string) => {
    return str.replace(/\\u[\dA-F]{4}/gi, (match: string) => {
      return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16))
    })
  }

  const getUserHistorybyThreadId = async (tId: string) => {
    let modifiedString = ''
    try {
      const userHistoryRespo = await fetch(`${baseUrl}/api/users/gethistory/${tId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: token as string,
        },
      })

      const userHistoryData = await userHistoryRespo.json()

      // setAllHistory(userHistoryData?.historydata)

      modifiedString = simplifyText(userHistoryData[0]?.SearchResult)

      userHistoryData[0].SearchResult = modifiedString

      setAllHistory(userHistoryData)
      // setIsLoading(false)
    } catch (error: any) {
      // console.log('This Error occur in fetching history ', error.message)
      showSnackbar(error?.message,'error')
    }
  }

  // Function 2

  const postHistory = async (
    searchTitle: string,
    searchResult: string,
    userId: number,
    threadId: string,
    tokenConsumed: any,
  ) => {
    try {
      const postResponse = await fetch(`${baseUrl}/api/users/StoreHistory1`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          authorization: token as string,

          key: 'Cross-Origin-Opener-Policy',
          value: 'same-origin allow-popups',
        },
        body: JSON.stringify({
          SearchTitle: searchTitle,
          SearchResult: searchResult,
          UserId: userId,
          ThreadId: threadId,
          Token: tokenConsumed,
        }),
      })

      const postHistoryData = await postResponse.json()
      // console.log('postHistoryData', postHistoryData)
      if (postHistoryData.status === true) {
        const data = postHistoryData?.data
        setThreadId((prevId) => (prevId = data?.ThreadId))
        getUserHistory(userId)
        getUserHistorybyThreadId(data?.ThreadId)
        // alert('postHistory executed status true')
      }
    } catch (error: any) {
      // console.log(error.message)
      showSnackbar(error?.message,'error')
    } finally {
      // setMessage('')
      setAnswer('')
    }
  }

  const getUserHistory = async (userId: number) => {
    try {
      const userHistoryRespo = await fetch(
        `${baseUrl}/api/users/FindHistoryAll/${userId}`,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            authorization: token as string,
          },
        },
      )

      const userHistoryData = await userHistoryRespo.json()
      // setUserHistory(userHistoryData.data)
      // setThreadId((prevThreadId: any) => (prevThreadId = userHistoryData?.data[0]?.ThreadId))
      // const tid: string = userHistoryData?.data[0]?.ThreadId
      // tid && navigate(`/MainContent/${tid}`)

      // console.log('userHistoryData', userHistoryData)
      if(userHistoryData.status === true){
        setUserHistory(userHistoryData?.data)
        }else{
          showSnackbar(userHistoryData?.message || 'Failed to fetch history','error')
        }
      // console.log('userHistoryData', userHistoryData?.data)
      // setThreadId((prevThreadId: any) => (prevThreadId = userHistoryData?.data[0]?.ThreadId))
      // const tid: string = userHistoryData?.data[0]?.ThreadId
      // tid && navigate(`/MainContent/${tid}`)
    } catch (error: any) {
      // console.log('This Error occur in fetching history ', error.message)
      showSnackbar(error?.message || 'Failed to fetch history','error')
    }
  }

  const editHistoryTItleAPi = async (title: any, historyId: any) => {

    try {
      const response = await fetch(`${baseUrl}/api/users/UpdateSerchTitle/${historyId as string}`, {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          authorization: token as string,
        },
        body: JSON.stringify({
          SearchTitle: title,
        }),
      })

      const editHistoryResponseData = await response.json()

      if (editHistoryResponseData.status === true) {
        getUserHistory(userId)
        showSnackbar(editHistoryResponseData?.message || 'Title edited successfully','success')
      }else{
        showSnackbar(editHistoryResponseData?.message || 'Failed to edit title','error')
      }
    } catch (error: any) {
      showSnackbar(error?.message || 'Failed to edit title.','error')
    }
  }

  const clearAllHistory = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/users/deleteallhistory${userId}`, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          authorization: token as string,
        },
      })

      const responseData = await response.json()

      if (responseData.status === true) {
        getUserHistory(userId)
        showSnackbar(responseData?.message || 'Chat history deleted successfully','success')
      } else {
        showSnackbar(responseData.message || 'Failed to clear chat history.','error')
      }
    } catch (error: any) {
      // console.log('Delete chat history error ', error.message)
      showSnackbar(error?.message,'error')
      getUserHistory(userId)
    } finally {
      getUserHistory(userId)
    }
  }

  // Edit QsetEditQuestionresuestion Scenario

  // Function For Python Server
  const fetchServerResponse = async (question: string) => {
    // let modifiedString = ''
    try {
      // console.log('question', question)
      setEditQuestion(question)
      question && setLastAskedQuestion(question)
      const a = question



      const response = await fetch(`${pythonUrl}/pyapi/search`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Promptquery: a }),
      })

      if (!response.ok) {
        throw new Error('Request failed')
      }

      if (response.body) {
        const reader = response.body.getReader()
        let chunks = ''

        while (true) {
          const { done, value } = await reader.read()
          if (done) {
            setResponseComplete(true)
            break
          }
          chunks += new TextDecoder().decode(value)
        }

        const match = chunks.match(/total_tokens_consumed":(\d+)/)
        const totalTokensConsumed = match ? match[1] : null

        setTokenConsumed(totalTokensConsumed)
        setGlobalTokenConsumed(totalTokensConsumed)
        const modifiedString = simplifyText(chunks)
        modifiedString && setServerResponse(modifiedString)
        // console.log('Last statement in fetchServerResponse',)
        setEditQuestion('')

        // setIsLoading(false)
      }


      // const data = await response.json()
      // console.log('going to return true')
      return true;
    } catch (error) {
      showSnackbar('An error occurred while submitting the form data', 'error')
      return false
    }
  }

  // Function to Store Edit History to Node Server

  const storeEditedHistory = async () => {
    try {
      const storeEditedHistoryResponse = await fetch(`${baseUrl}/api/users/StoreHistory1`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          authorization: token as string,

          key: 'Cross-Origin-Opener-Policy',
          value: 'same-origin allow-popups',
        },
        body: JSON.stringify({
          SearchTitle: lastAskedQuestion,
          SearchResult: serverResponse,
          UserId: userId,
          ParentId: questionId,
          ThreadId: threadId,
          Token: tokenConsumed,
        }),
      })

      const storeEditedHistoryResponseData = await storeEditedHistoryResponse.json()

      if (storeEditedHistoryResponseData.status === true) {
        setLastAskedQuestion('')
        setUserInput('')
        setEditQuestionList(storeEditedHistoryResponseData.data)
        threadId !== '' &&
          threadId !== null &&
          threadId !== undefined &&
          getUserHistorybyThreadId(threadId)
        setIsQuestionUpdating(false)
      }
    } catch (error: any) {
      // console.log('Error in storeEditedHistory Function : ', storeEditedHistory)
      showSnackbar(error?.message,'error')
      setIsQuestionUpdating(false)
    }
  }

  // To get history by thread Id

  return (
    <>
      <SidebarContext.Provider
        value={{

          upgradeSubscriptionDialog,
          setUpgradeSubscriptionDialog,
          locationUrl,
          setLocationUrl,
          uniqueString,
          setUniqueString,
          uploadedFileName,
          setUploadedFileName,
          isRefFileUploaded,
          setIsRefFileUploaded,
          dataInRightDrawer,
          setDataInRightDrawer,
          openVerifier,
          setOpenVerifier,
          historyClicked,
          setHistoryClicked,
          isLoggedIn,
          setIsLoggedIn,
          answerToLeftDrawer,
          setAnswerToLeftDrawer,
          sendBtnClicked,
          setSendBtnClicked,
          newChatClicked,
          setNewChatClicked,
          isQuestionUpdating, setIsQuestionUpdating,
          simplifyText,
          selectedTab,
          setSelectedTab,
          sendAnswerToLeftDrawer,
          setSendAnswerToLeftDrawer,
          isLoading,
          setIsLoading,
          lastAskedQuestion,
          setLastAskedQuestion,
          lastAnsweredQuestion,
          setLastAnsweredQuestion,
          globalTokenConsumed,
          setGlobalTokenConsumed,
          sidebarOpen,
          setSidebar,
          aiStorage,
          setAiStorage,
          inputMessage,
          setInputMessage,
          answer,
          setAnswer,
          userStatus,
          handleSearchCount,
          deleteUniqueString,
          sendData,
          getHistory,

          message,
          setMessage,
          getUserHistory,
          userHistory,
          setUserHistory,
          editHistoryTItleAPi,
          allHistory,
          setAllHistory,
          setIsResponseComplete,
          threadId,
          setThreadId,
          clearAllHistory,

          buttonsDisabled,
          setButtonsDisabled,

          // Message Context

          messages,
          activeMessageContext,
          setActiveMessageContext,
          searchValue,
          setSearchValue,

          // Edit Question Functionality
          fetchServerResponse,
          editQuestionList,
          setQuestionId,
          userInput,
          setUserInput,

          // token consumption

          // thredId Deleted
          isThredIdDelete,
          setIsThreadIdDelete,
          getUserHistorybyThreadId
        }}
      >
        {children}
      </SidebarContext.Provider>
    </>
  )
}

export const useSidebarContext = () => {
  const sidebarContext = useContext(SidebarContext)
  if (sidebarContext === undefined) {
    throw new Error('useSidebarContext must be inside a SidebarProvider')
  }
  return sidebarContext
}

export default SidebarContext
