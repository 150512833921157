import React from 'react'
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import { PlusIcon } from '../../../../components/Icons'
import { useNavigate } from 'react-router-dom'

import { useSidebarContext } from '../../../../contexts/Old_SidebarContext'
// import baseUrl from '../../../../config/baseUrl'


const NewChatButton = () => {
  const {
    setInputMessage,

    setSelectedTab,
    setAllHistory,
    setThreadId,
    setSidebar,
    setNewChatClicked, setUploadedFileName, setIsRefFileUploaded
  } = useSidebarContext()

  const navigate = useNavigate()
  // const token = localStorage.getItem('token')
  // const userId = localStorage.getItem('userId')
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // const createChat = (randomObject: any) => {
  //   return fetch(`${baseUrl}/api/users/StoreHistory1`, {
  //     method: 'POST',
  //     mode: 'cors',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       SearchTitle: randomObject.searchTitle,
  //       searchResult: randomObject.searchResult,
  //       UserId: userId,
  //       ThreadId: threadId,
  //       Token: globalTokenConsumed,
  //     }),
  //   })
  // }

  // const generateRandomString = (length: any) => {
  //   const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  //   let result = ''
  //   for (let i = 0; i < length; i++) {
  //     const randomIndex = Math.floor(Math.random() * characters.length)
  //     result += characters.charAt(randomIndex)
  //   }
  //   return result
  // }
  // const generateRandomObject = () => {
  //   const randomSearchTitle = generateRandomString(10)
  //   const randomSearchResult = generateRandomString(20)

  //   const randomObject = {
  //     searchTitle: randomSearchTitle,
  //     searchResult: randomSearchResult,
  //   }

  //   return randomObject
  // }
  const newChat = () => {
    setIsRefFileUploaded(false)
    setUploadedFileName('')
    setInputMessage('')
    setNewChatClicked(true)
    setSelectedTab(1)
    setAllHistory([])
    setThreadId(null)
    if (isMobile) {
      setSidebar(false)
    }
    navigate('/NewChat')
  }
  return (
    <Button onClick={newChat} variant='contained' startIcon={<PlusIcon />}>
      <Typography variant='body2'>New Chat</Typography>
    </Button>
  )
}

export default NewChatButton
