import React, { useEffect, useState, type SetStateAction } from 'react'
import {
  Modal,
  Box,
  Button,
  Typography,
  FormControl,
  FormLabel,
  Stack,
  Select,
  Divider,
  MenuItem,
  styled,
  type FormLabelProps,
  Paper,
  IconButton,
  useTheme,
  useMediaQuery,
  TextField,
  InputAdornment,
  Tooltip,
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  CircularProgress
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import Pagination from '../../../../../components/Pagination'
// import { useNavigate } from 'react-router-dom'
import { useSidebarContext } from '../../../../../contexts/Old_SidebarContext'
import baseUrl from '../../../../../config/baseUrl'
import {
  acts,
  affidavits,
  deedApplications,
  districtAndSessionsCourtApplications,
  arbitration,
  drtApplications,
  familyCourtApplications,
  highCourtApplications,
  writPetitions,
  jmfcApplications,
  notices,
  judgements,
  // formats,
  biologicalDiversity,
  climateChangeAndPollution,
  importantArticles,
  economicOffences,
  civilProcedure,
  familyLaw,
  divorce,
  marriage,
  adoption,
  companiesBankruptcyAndInsolvency,
  contract,
  bankingAndFinance,
  companiesLaw,
  industrialRelation,
  labourAndEmploymentLaws,
  roadSafety,
  sexualOffensesAndHarrashmentAct,
  murder,
  fraudAndTheft,
  criminalProcedure,
  incomeTax,
  indirectTax,
  caseBased,
  domesticLegislationForInternationalLawAndConvention,
  patentTrademarkAndGI,
} from './data.js'
import { useSnackbar } from '../../../../../components/SnackbarProvider'
// import { SubCategories } from './data'

const StyledFormLabel = styled(FormLabel)<FormLabelProps>(({ theme }) => ({
  ...theme.typography.subtitle2,
  marginBottom: theme.spacing(1.5),
}))

const Categories = () => {
  const [open, setOpen] = useState(false)
const showSnackbar = useSnackbar()

  const [showCategory2, setShowCategory2] = useState(false)
  const [showCategory3, setShowCategory3] = useState(false)
  // const [optionsForSubCategories, setOptionsForSubCategories] = useState([])
  const [selectedCategory1, setSelectedCategory1] = useState('')
  const [selectedCategory2, setSelectedCategory2] = useState('')
  const [selectedCategory3, setSelectedCategory3] = useState('')
  const [hints, setHints] = useState<string[]>([])
  const [hasData, setHasData] = useState(false)
  const [category1, setCategory1] = useState<Array<{ value: string; label: string }>>([
    { value: 'acts', label: 'Acts' },
    { value: 'judgements', label: 'Judgements' },
    { value: 'formats', label: 'Formats / Notices' },
    { value: 'indianConstitution', label: 'Indian Constitution' },

  ])
  const [isLoading, setIsLoading] = useState(false);
  const [category2, setCategory2] = useState<Array<{ value: string; label: string }>>([])
  const [category3, setCategory3] = useState<string[]>([])
  const [states, setStates] = useState<string[]>([])
  const [selectedState, setSelectedState] = React.useState('')
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  // search
  const [showIcon, setShowIcon] = React.useState(false)
  const [searchText, setSearchText] = React.useState('')
  // pagination
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  // state act dialog
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({ question: '', answer: '' });
  const { setMessage, setInputMessage, setSidebar } = useSidebarContext()

  // const navigate = useNavigate()

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/indianstates/getIndianStates`)
        const data = await response?.json()

        if (data?.status) {
          // Filter states where is_state is true and map to their names
          const stateNames = data?.data?.map((state: { name: string }) => state?.name) // Map to their names

          setStates(stateNames)
        }
      } catch (error: any) {
        showSnackbar(error,'error')
      }
    }

    fetchStates()
  }, [])

  useEffect(() => {
    if (selectedCategory3) {
      // Check if state is selected
      fetchStateWiseActs(selectedCategory3, searchText)
    }
  }, [currentPage, selectedCategory3])

  const fetchStateWiseActs = async (stateValue: string, searchText: string) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${baseUrl}/api/indianstates/getQuestions?stateName=${stateValue}&query=${searchText ? searchText.toLowerCase() : ''}&page=${currentPage}`,
      )
      const data = await response?.json()

      if (data?.status) {
        // Assuming you are mapping over an array called `data.data` to extract names
        setTotalPages(data?.totalPages)

        setHints(data?.data) // Assuming `setStates` is the correct function here
        setHasData(true)
      } else {
        setTotalPages(1)

        setHints([])
        setHasData(false)
      }
    } catch (error: any) {
      showSnackbar(error.message || 'An error occurred','error') // Improved error handling
      setTotalPages(1)

      setHints([])
      setHasData(false)
    }
    finally {
      setIsLoading(false); // Hide loader when API call finishes
    }
  }

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setSearchText('')
    setShowIcon(false)
    setIsLoading(false)
  }

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)', // Centers the modal
    width: { xs: '90vw', md: '55vw' },
    height: '80vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'scroll',
  }

  const handleCategory1 = (value: string) => {
    setSelectedCategory2('')
    setSelectedCategory1(value)
    setShowCategory3(false)

    if (value === 'acts') {
      // setHints(acts as SetStateAction<string[]>);
      // setShowCategory2(false)
      // handleOpen()
      const subCatagories = [
        { value: 'stateActs', label: 'State Acts & Union Territories Acts' },
        { value: 'centralActs', label: 'Central Acts' },
      ]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else if (value === 'judgements') {
      setHints(judgements as SetStateAction<string[]>)
      setShowCategory2(false)
      handleOpen()
    } else if (value === 'formats') {
      const subCatagories = [
        { value: 'affidavits', label: 'Affadavits' },
        { value: 'deedApplications', label: 'Deed Applications' },
        { value: 'arbitration', label: 'Arbitration' },
        {
          value: 'districtAndSessionsCourtApplications',
          label: 'District and Sessions Court Applications',
        },
        { value: 'drtApplications', label: 'DRT Applications' },
        { value: 'familyCourtApplications', label: 'Family Court Applications' },
        { value: 'highCourtApplications', label: 'High Court Applications' },
        { value: 'writPetitions', label: 'WRIT Petitions' },
        { value: 'jmfcApplications', label: 'JMFC Applications' },
        { value: 'notices', label: 'Notices' },
      ]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else if (value === 'environmentalLaws') {
      const subCatagories = [
        { value: 'biologicalDiversity', label: 'Biological Diversity' },
        { value: 'climateChangeAndPollution', label: 'Climate Change and Pollution' },
      ]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else if (value === 'indianConstitution') {
      const subCatagories = [{ value: 'importantArticles', label: 'Important Articles' }]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else if (value === 'civil') {
      const subCatagories = [
        { value: 'economicOffences', label: 'Economic Offences' },
        { value: 'civilProcedure', label: 'Civil Procedure' },
        { value: 'familyLaw', label: 'Family Law' },
        { value: 'divorce', label: 'Divorce' },
        { value: 'marriage', label: 'Marriage' },
        { value: 'adoption', label: 'Adoption' },
      ]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else if (value === 'corporateLaw') {
      const subCatagories = [
        {
          value: 'companiesBankruptcyAndInsolvency',
          label: 'Companies, Bankruptcy and Insolvency',
        },
        { value: 'contract', label: 'Contract' },
        { value: 'bankingAndFinance', label: 'Banking and Finance' },
        { value: 'companiesLaw', label: 'Companies Law' },
        { value: 'industrialRelation', label: 'Industrial Relation' },
        { value: 'labourAndEmploymentLaws', label: 'Labour and Employment Laws' },
      ]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else if (value === 'criminal') {
      const subCatagories = [
        {
          value: 'roadSafety',
          label: 'Road Safety',
        },
        {
          value: 'sexualOffensesAndHarrashmentAct',
          label: 'Sexual Offenses and  Harassments act',
        },
        { value: 'murder', label: 'Murder' },
        { value: 'fraudAndTheft', label: 'Fraud and Theft' },
        { value: 'criminalProcedure', label: 'Criminal Procedure' },
      ]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else if (value === 'taxation') {
      const subCatagories = [
        { id: 1, value: 'incomeTax', label: 'Income Tax' },
        { id: 2, value: 'indirectTax', label: 'Indirect Tax' },
      ]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else if (value === 'conditional') {
      const subCatagories = [
        {
          id: 1,
          value: 'caseBased',
          label: 'Case Based',
        },
      ]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else if (value === 'internationalLaws') {
      const subCatagories = [
        {
          id: 1,
          value: 'domesticLegislationForInternationalLawAndConvention',
          label: 'Domestic Legislation for International Law and Convention',
        },
      ]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else if (value === 'intellectualProperty') {
      const subCatagories = [
        {
          id: 1,
          value: 'patentTrademarkAndGI',
          label: 'Patent Trademark and GI',
        },
      ]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else {
      showSnackbar('Select proper input','error')
    }
  }

  const handleCategory2 = (value: string) => {

    setSelectedCategory2(value)
    setSelectedCategory3('')
    // console.log('value', value)
    if (value === 'affidavits') {
      setHints(affidavits as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'stateActs') {
      // setHints(acts as SetStateAction<string[]>);
      // handleOpen()
      setCategory3([...states])
      setShowCategory3(true)
    } else if (value === 'centralActs') {
      setHints(acts as SetStateAction<string[]>)
      setShowCategory3(false)
      handleOpen()
    } else if (value === 'deedApplications') {
      setHints(deedApplications as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'arbitration') {
      setHints(arbitration as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'districtAndSessionsCourtApplications') {
      setHints(districtAndSessionsCourtApplications as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'drtApplications') {
      setHints(drtApplications as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'familyCourtApplications') {
      setHints(familyCourtApplications as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'highCourtApplications') {
      setHints(highCourtApplications as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'writPetitions') {
      setHints(writPetitions as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'jmfcApplications') {
      setHints(jmfcApplications as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'notices') {
      setHints(notices as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'biologicalDiversity') {
      setHints(biologicalDiversity as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'climateChangeAndPollution') {
      setHints(climateChangeAndPollution as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'importantArticles') {
      setHints(importantArticles as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'economicOffences') {
      setHints(economicOffences as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'civilProcedure') {
      setHints(civilProcedure as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'familyLaw') {
      setHints(familyLaw as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'divorce') {
      setHints(divorce as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'marriage') {
      setHints(marriage as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'adoption') {
      setHints(adoption as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'companiesBankruptcyAndInsolvency') {
      setHints(companiesBankruptcyAndInsolvency as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'contract') {
      setHints(contract as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'bankingAndFinance') {
      setHints(bankingAndFinance as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'companiesLaw') {
      setHints(companiesLaw as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'industrialRelation') {
      setHints(industrialRelation as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'labourAndEmploymentLaws') {
      setHints(labourAndEmploymentLaws as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'roadSafety') {
      setHints(roadSafety as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'sexualOffensesAndHarrashmentact') {
      setHints(sexualOffensesAndHarrashmentAct as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'murder') {
      setHints(murder as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'fraudAndTheft') {
      setHints(fraudAndTheft as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'criminalProcedure') {
      setHints(criminalProcedure as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'incomeTax') {
      setHints(incomeTax as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'indirectTax') {
      setHints(indirectTax as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'caseBased') {
      setHints(caseBased as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'domesticLegislationForInternationalLawAndConvention') {
      setHints(domesticLegislationForInternationalLawAndConvention as SetStateAction<string[]>)
      handleOpen()
    } else if (value === 'patentTrademarkAndGI') {
      setHints(patentTrademarkAndGI as SetStateAction<string[]>)
      handleOpen()
    } else {
      showSnackbar('Select proper input','warning')
    }
  }


  function removeSpaces(str: string) {
    return str.replace(/\s+/g, '');
  }

  const handleCategory3 = (value: string) => {
    const text = removeSpaces(value)
    setSelectedCategory3(text)
    setSelectedState(value)
    handleOpen()
    // fetchStateWiseActs(e.target.value,searchText)
  }

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setShowIcon(false)
    }
    setSearchText(e.target.value)
    setShowIcon(true)
  }

  const handleSearchClick = () => {
    setCurrentPage(1)
    fetchStateWiseActs(selectedCategory3, searchText) // Call the API manually here
  }

  const handleEnterKeyPress = async (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setCurrentPage(1)
      fetchStateWiseActs(selectedCategory3, searchText) // Call the API manually here
    }
    if (event.key === 'Backspace' || event.key === 'Delete') {
      if (searchText.length === 0) {
        handleClearSearch()
      }
    }
  }
  const handleClearSearch = () => {
    setCurrentPage(1)
    setShowIcon(false)
    setSearchText('')
    fetchStateWiseActs(selectedCategory3, '') // Call the API manually here
  }

  const handleActClick = (label: string) => {
    // console.log('handleActClick label', label)
    setInputMessage(label)
    setMessage('')
    // navigate(`/NewChatQuestion/${label}`)
    setTimeout(() => {
      handleClose()
      if (isMobile) {
        setSidebar(false)
      }
    }, 300)
  }

  // state act
  const handleOpenDialog = (question: string, answer: string) => {
    setDialogData({ question, answer });

  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const handleStateActClick = async (label: string) => {
    setDialogOpen(true);
    const formData = {
      stateName: selectedCategory3,
      stateQuestions: label
    };
    try {
      const response = await fetch(`${baseUrl}/api/indianstates/getAnswersData`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (!response?.ok) {
        throw new Error(`HTTP error! Status: ${response?.status}`);
      }

      const data = await response?.json();
      // console.log('Response data:', data);
      if (data?.status) {
        // Open the dialog with the question (label) and answer (data.data)
        handleOpenDialog(label, data?.data);
      } else {
        handleOpenDialog(label, 'No record found for this prompt');
      }
      // Handle the response data as needed
    } catch (error) {
      showSnackbar('Error while fetching data:', 'error');
      handleOpenDialog(label, 'No record found for this prompt');
    }
  }

  return (
    <>
      <Stack spacing={2.5} paddingLeft='1rem'>
        <FormControl>
          <StyledFormLabel focused={false}>Acts / Judgements / Formats</StyledFormLabel>
          <Select
            value={selectedCategory1}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value=' ' selected>
              <em>Select</em>
            </MenuItem>

            {category1?.map((category) => {
              return (
                <MenuItem key={category?.value} value={category?.value} onClick={() => { handleCategory1(category?.value) }}>
                  {category?.label}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>


        {showCategory2 && (
          <>
            <Divider />

            <FormControl>
              <StyledFormLabel focused={false}>Sub-Categories</StyledFormLabel>
              <Select

                value={selectedCategory2}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value=' ' selected>
                  <em>Select</em>
                </MenuItem>
                {category2?.map((category) => {
                  return (
                    <MenuItem key={category?.value} value={category?.value} onClick={() => { handleCategory2(category?.value) }}>
                      {category?.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </>
        )}


        {showCategory3 && (
          <>
            <Divider />
            <FormControl>
              <StyledFormLabel focused={false}>States & Union Territories</StyledFormLabel>
              <Select

                value={selectedState}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value=' ' selected>
                  <em>Select State</em>
                </MenuItem>
                {category3?.map((state, index) => {
                  return (
                    <MenuItem key={index} value={state} onClick={() => { handleCategory3(state) }}>
                      {state}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </>
        )}
      </Stack>

      {/* ------------------------- this section will be display in the middle screen --------------------------- */}
      <div>
        {/* <Button variant='contained' onClick={handleOpen}>
          Open Modal
        </Button> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-title'
          aria-describedby='modal-description'
          sx={{ zIndex: 10000000 }}
        >
          <Box sx={modalStyle}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography id='modal-title' variant='h6' component='h2'>
                Prompt Hints
              </Typography>

              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            {isLoading ? (
              <Grid container justifyContent="center" alignItems="center" style={{ height: '200px' }}>
                <CircularProgress />
              </Grid>
            ) : (
              <>
                {selectedCategory2 === 'stateActs' && hasData ? (
                  <Box>
                    <Box padding='0px 10px'>
                      <TextField
                        value={searchText}
                        name='nameMenuSearchRef'
                        variant='outlined'
                        placeholder='Enter text to search'
                        fullWidth
                        margin='normal'
                        onChange={handleChange}
                        onKeyDown={handleEnterKeyPress}
                        autoComplete='off'
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start' style={{ margin: 0, padding: 0 }}>
                              <IconButton onClick={handleSearchClick}>
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position='start' style={{ margin: 0, padding: 0 }}>
                              {showIcon && (
                                <Tooltip title='Clear search' placement='right'>
                                  <IconButton onClick={handleClearSearch}>
                                    <CloseIcon style={{ color: 'red' }} />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            paddingLeft: 1,
                            paddingRight: 1,
                          },
                          '& .MuiOutlinedInput-root .MuiInputBase-input': {
                            paddingLeft: '5px',
                          },
                          '& .MuiInputBase-input::placeholder': {
                            color: '#666 !important',
                            opacity: 0.4,
                          }
                        }}
                      />

                      {/* pagination */}
                      {totalPages !== 0 && < Grid
                        container
                        style={{
                          display: 'flex',
                          overflow: 'hidden',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: 'transparent',
                          padding: '5px 0 10px 10px',
                          lineHeight: '50px',
                          zIndex: 11,
                        }}
                      >
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={handlePageChange}
                        />
                      </Grid>}

                      {hints?.length > 0 ? (
                        hints?.map((item: any, index: number) => {
                          return (
                            <Box
                              key={index}
                              style={{
                                margin: 0,
                                padding: 0,
                                boxSizing: 'border-box',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <h3
                                style={{
                                  color: 'magenta',
                                  margin: 0,
                                  padding: 0,
                                  marginRight: '5px',
                                }}
                              >
                                &#x2022;
                              </h3>
                              <Paper
                                sx={{
                                  my: '0.3rem',
                                  p: '0.3rem 0.4rem',
                                  cursor: 'pointer',
                                  width: '100%',
                                  position: 'relative'
                                }}
                                onClick={() => {
                                  handleStateActClick(item)
                                }}
                              >
                                {item}
                              </Paper>
                            </Box>
                          )
                        })
                      ) : (
                        <div>No data found.</div>
                      )}
                    </Box>
                  </Box>
                ) : (
                  <>
                    {hints?.length > 0 ? (
                      hints?.map((item: any, index: number) => {
                        return (
                          <Box
                            key={index}
                            style={{
                              margin: 0,
                              padding: 0,
                              boxSizing: 'border-box',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <h3
                              style={{
                                color: 'magenta',
                                margin: 0,
                                padding: 0,
                                marginRight: '5px',
                              }}
                            >
                              &#x2022;
                            </h3>
                            <Paper
                              sx={{
                                my: '0.3rem',
                                p: '0.3rem 0.4rem',
                                cursor: 'pointer',
                                width: '100%',
                              }}
                              onClick={() => {
                                handleActClick(item)
                              }}
                            >
                              {item}
                            </Paper>
                          </Box>
                        )
                      })
                    ) : (
                      <div>No data found.</div>
                    )}
                  </>
                )}
              </>
            )}
          </Box>
        </Modal>
        {/* Dialog for displaying question and answer */}
        <Dialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
          sx={{ zIndex: 10000000, }}
          PaperProps={{
            sx: {
              backgroundColor: 'background.light', // Change this to the desired background color
            },
          }}
        >
          <DialogTitle id="dialog-title" sx={{ fontSize: '16px', paddingBottom: '8px' }}>
            {dialogData?.question}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="dialog-description" sx={{
              fontSize: '14px', textAlign: 'justify', backgroundColor: '#eeeeee', padding: '8px 8px'
            }}>
              {dialogData?.answer}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} variant="contained" sx={{
              height: '40px',
              width: '100px',
              marginRight: '10px',
              marginTop: '0px',
              color: 'black',
              // fontSize: '10px'
            }}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  )
}

export default Categories
