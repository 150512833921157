import React from 'react'
import { Dialog, DialogContent, Typography, Stack, Button } from '@mui/material'
import { useSnackbar } from '../../SnackbarProvider'
import { useNavigate } from 'react-router-dom'
import baseUrl from '../../../config/baseUrl'

interface Props {
  open: boolean
  onClose: () => void
}

const DeleteAccountDialog = ({ open, onClose }: Props) => {
  const navigate = useNavigate()
  const userId = localStorage.getItem('userId')
  const token = localStorage.getItem('token')
  const showSnackbar = useSnackbar();

  const handleClick = async () => {
    try {
        const response = await fetch(`${baseUrl}/api/users/DeleteAccount/${userId as string}`, {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                authorization: token as string,
            },
        });

        const responsedata = await response.json();

        if (response.ok) {
            localStorage.clear();
            navigate('/auth/register', { replace: true });
            showSnackbar(responsedata.message, 'success');
        } else {
            showSnackbar(responsedata.message, 'error');
        }
    } catch (error: any) {
        showSnackbar('Something went wrong, not able to delete account', 'error');
    }
    onClose();
};

  return (
    <Dialog open={open} maxWidth='xs' fullWidth>
      <DialogContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 3.5,
          py: 4.5,
          px: 6,
        }}
      >
        <Stack spacing={1.5}>
          <Typography variant='subtitle2' color='textSecondary'>
            Are you sure, you want to Delete your Account?
          </Typography>
          <Typography variant='subtitle1' align='center'>
            On deleting your Account, all the chat history will get deleted automatically.
          </Typography>
        </Stack>
        <Stack direction='row' justifyContent='center' spacing={2} width={1}>
          <Button variant='outlined' sx={{ p: 0, maxWidth: 114, height: 44 }} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant='contained'
            color='error'
            sx={{ p: 0, maxWidth: 88, height: 44 }}
            onClick={handleClick}
          >
            Delete
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteAccountDialog
